import React, { useState, useEffect } from "react";
import RadioStyle from "../../../components/RadioButton/RadioButton.module.css";
import {
  Box,
  Grid,
  Link,
  Typography,
  Divider,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { makeStyles } from "@material-ui/core/styles";
import { first, last, isEmpty } from "lodash/fp";
import { WithContext as ReactTags } from "react-tag-input";
import NoRecordFound from "./NoRecordFound";

import Autocomplete from "../../Autocomplete";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 600,
  },
  modal_header: {
    backgroundColor: "#A3CED1",
    height: "50px",
  },
  header_text: {
    marginLeft: "10px",
  },
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "150px",
    overflowY: "scroll",
    direction: "rtl",
  },
  contentWrapper: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "start",
  },
  bulletText: {
    fontSize: "13px",
  },
  bulletIcon: {
    marginRight: "5px",
    fontSize: "15px",
    marginTop: "3px",
  },
  dialogTitleText: {
    backgroundColor: "#DF8E8E",
    color: "#090909",
    borderColor: "#979797",
  },
  typoText: {
    color: "#0D0D0D",
    fontWeight: 500,
    fontSize: 18,
  },
  contentWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  EditIcon: {
    fontSize: "30px",
    color: "#4A4A4A",
    margin: "5px",
    float: 'right',
  },
  crossIcon: {
    marginLeft: "70px",
    fontSize: "15px",
    cursor: "pointer",
    "&:hover": {
      color: "#DF8E8E",
    },
  },
  addCategory: {
    marginTop: 5,
  },
  inputTags: {
    "& input": {
      width: "100%",
      height: "30px",
      lineHeight: "30px",
      fontSize: "14px",
      fontWeight: "400",
      border: "1px solid #D0D0D0",
      borderRadius: "5px",
      color: "#000",
      padding: "0 15px",
      "&:focus": {
        outline: "none",
      },
      "&::placeholder": {
        color: "#989c9c",
      },
    },
  },
  fontSize24: {
    fontSize: 24,
  },
  addTagBtn: {
    marginTop: 4,
    marginLeft: 10,
  }
}));
const ListModal = (props) => {
  const classes = useStyles();
  const { open, onClose, onClick, data, handleAddition,
          searchCategories, selectableArrayHashById,
          categoryList, categoryId, addTag, removeCategory } = props;

  const [addCategory, setAddCategory] = useState(false);
  const [isDefault, setIsDefault] = useState(true);

  useEffect(() => {
    if (!open) {
      setAddCategory(false);
      setIsDefault(true);
    }
  }, [open]);

  const getData = () => {
    if (isDefault){
      return data.slice(0, 5)
    }
    return data
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: 600,
            height: 450,
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          className={classes.dialogTitleText}
        >
          Style Values List View
          <FontAwesomeIcon
            className={classes.EditIcon}
            icon={faPlusCircle}
            onClick={() => setAddCategory(true)}
          />
        </DialogTitle>
        <DialogContent>
          {addCategory && (
            <Grid container>
              <Grid item xs={10} md={7} className={classes.addCategory}>
                <div className={classes.inputTags}>
                  <ReactTags
                    suggestions={categoryList}
                    handleAddition={(event) =>
                      handleAddition(event, selectableArrayHashById(categoryId))
                    }
                    autofocus={true}
                    placeholder="Search Category"
                    id={"categoryTag"}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={2}
                md={1}
                className={classes.addTagBtn}
                onClick={() =>
                  addTag(
                    "categoryTag",
                    selectableArrayHashById(categoryId)
                  )
                }
              >
                <Button
                  className={classes.btnTextColor}
                  size="small"
                  variant="outlined"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          )}
          <DialogContentText
            id="alert-dialog-description"
            className={classes.contentWrapper}
          >
            <div>
              {data &&
                getData().map((option, index) => (
                  <Box display="flex" mt={1} className={classes.typoText} key={`option_${index}`}>
                    <Grid md={11}>{option.title}</Grid>
                    <Grid
                      md={1}
                    >
                      <FontAwesomeIcon
                        className={classes.crossIcon}
                        icon={faTimesCircle}
                        onClick={() => removeCategory(option.id, {type: categoryId, approve: false})}
                      />
                    </Grid>
                  </Box>
                ))}
              {data.length > 5 && isDefault &&
                <>
                  <span className={classes.fontSize24} onClick={() => setIsDefault(false)}>...</span>
                  <Box display="flex" mt={1} className={classes.typoText} key={`option_${last(data).id}`}>
                    <Grid md={11}>{last(data).title}</Grid>
                    <Grid
                      md={1}
                    >
                      <FontAwesomeIcon
                        className={classes.crossIcon}
                        icon={faTimesCircle}
                        onClick={() => removeCategory(last(data).id, categoryId)}
                      />
                    </Grid>
                  </Box>
                 </> 
              }
            </div>
          </DialogContentText>
          {isEmpty(data) &&
            <NoRecordFound 
              data={data}
            />
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={onClick} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ListModal;
