import React from 'react'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'

import DeleteIcon from '../../icons/Delete'
import SidesIcon from '../../icons/Sides'

const SidesMenu = ({
  sidesLength,
  onCreateSide,
  onDuplicateSide,
  onDeleteSide,
}) => {
  return (
    <Grid container>
      <Grid item xs={4}>
        <IconButton
          disabled={sidesLength === 1}
          onClick={onDeleteSide}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
      <Grid container item xs={4} justify="center">
        <IconButton onClick={onDuplicateSide}>
          <SidesIcon />
        </IconButton>
      </Grid>
      <Grid container item xs={4} justify="flex-end">
        <IconButton onClick={onCreateSide}>
          <AddIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

SidesMenu.propTypes = {
  sidesLength: PropTypes.number.isRequired,
  onCreateSide: PropTypes.func.isRequired,
  onDuplicateSide: PropTypes.func.isRequired,
  onDeleteSide: PropTypes.func.isRequired,
}

export default SidesMenu
