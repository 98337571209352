import React, { useState, useEffect } from "react";
import RadioStyle from "../../../components/RadioButton/RadioButton.module.css";
import {
  Box,
  Grid,
  Link,
  Typography,
  Divider,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPlusCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { makeStyles } from "@material-ui/core/styles";
import { first, last, isEmpty } from "lodash/fp";
import CloseIcon from "@material-ui/icons/Close";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import NoRecordFound from "./NoRecordFound";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 600,
  },
  modal_header: {
    backgroundColor: "#A3CED1",
    height: "50px",
  },
  header_text: {
    marginLeft: "10px",
  },
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "150px",
    overflowY: "scroll",

    direction: "rtl",
  },
  contentWrapper: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "start",
  },
  bulletText: {
    fontSize: "13px",
  },
  bulletIcon: {
    marginRight: "5px",
    fontSize: "15px",
    marginTop: "3px",
  },
  dialogTitleText: {
    backgroundColor: "#A3CED1",
    color: "#090909",
    borderColor: "#979797",
  },
  typoText: {
    color: "#0D0D0D",
    fontWeight: 500,
    fontSize: 18,
    marginTop: 40,
  },
  contentWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  EditIcon: {
    fontSize: "22px",
    color: "#4A4A4A",
    margin: "5px",
  },
  crossIcon: {
    marginLeft: "50px",
    fontSize: "12px",
  },
  noRecord: {
    color: "#0D0D0D",
    fontWeight: 500,
    fontSize: 18,
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));
const SortModal = (props) => {
  const classes = useStyles();
  const { open, onClose, onClick, modalContent, sortCategory } = props;
  const [isDefault, setIsDefault] = useState(true);

  useEffect(() => {
    if (!open) {
      setIsDefault(true);
    }
  }, [open]);

  const getData = () => {
    if (isDefault) {
      return modalContent.bodyText.slice(0, 5);
    }
    return modalContent.bodyText;
  };

  const onDragEnd = (param) => {
    const srcI = param.source.index;
    const DesI = param.destination?.index;
    if (DesI) {
      modalContent.bodyText.splice(
        DesI,
        0,
        modalContent.bodyText.splice(srcI, 1)[0]
      );
      sortCategory(parseInt(param.draggableId, 10), {
        type: modalContent.categoryId,
        sequence: DesI,
      });
    }
  };

  return (
    <DragDropContext onDragEnd={(param) => onDragEnd(param)}>
      <div>
        <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              width: 600,
              height: modalContent.checkBox ? 450 : "auto",
            },
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            className={classes.dialogTitleText}
          >
            {modalContent.headerText}
            <IconButton
              aria-label="close"
              onClick={onClick}
              className={classes.closeButton}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Droppable droppableId="droppable-1">
            {(provided, _) => (
              <div>
                <DialogContent
                  className="characters"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <DialogContentText
                    id="alert-dialog-description"
                    className={classes.contentWrapper}
                  >
                    <div>
                      {modalContent.checkBox &&
                        getData().map((text, index) => (
                          <Draggable
                            key={text.id}
                            draggableId={"" + text.id}
                            index={index}
                          >
                            {(provided, _) => (
                              <div>
                                <Box
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={RadioStyle.radio}
                                  mt={1}
                                  display="block"
                                  overflowX="scroll"
                                >
                                  <input
                                    onClick={props.onInputChange}
                                    type="radio"
                                    value={text.title}
                                    name={text.title}
                                    id={`radio${index}`}
                                    className={RadioStyle.radioInput}
                                  />
                                  <label
                                    htmlFor="radio${index}"
                                    className={RadioStyle.radioLabel}
                                  >
                                    {text.title}
                                  </label>
                                </Box>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                      {modalContent.bodyText.length > 5 && isDefault && (
                        <span>
                          <span
                            className={classes.fontSize24}
                            onClick={() => setIsDefault(false)}
                          >
                            ...
                          </span>

                          <Box
                            className={RadioStyle.radio}
                            mt={1}
                            display="block"
                            overflowX="scroll"
                          >
                            <input
                              onClick={props.onInputChange}
                              type="radio"
                              value={last(modalContent.bodyText).title}
                              name={last(modalContent.bodyText).title}
                              id="radio${index}"
                              className={RadioStyle.radioInput}
                            />
                            <label
                              htmlFor="radio${index}"
                              className={RadioStyle.radioLabel}
                            >
                              {last(modalContent.bodyText).title}
                            </label>
                          </Box>
                        </span>
                      )}

                      {!modalContent.checkBox &&
                        first(modalContent.bodyText).title}
                      {!isEmpty(modalContent.bodyText) && (
                        <Typography className={classes.typoText}>
                          {modalContent.footerText}
                        </Typography>
                      )}
                    </div>
                  </DialogContentText>
                </DialogContent>
              </div>
            )}
          </Droppable>
          {isEmpty(modalContent.bodyText) && (
            <NoRecordFound data={modalContent.bodyText} />
          )}
        </Dialog>
      </div>
    </DragDropContext>
  );
};

export default SortModal;
