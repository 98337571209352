import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { isEmpty } from 'lodash/fp'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Toolbar from '@material-ui/core/Toolbar'
import Paper from '@material-ui/core/Paper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort } from '@fortawesome/free-solid-svg-icons'
import { Box } from '@material-ui/core'
import ReactTooltip from 'react-tooltip'

import HttpClient from '../../../services/HttpClient'
import AuthContext from '../../../contexts/AuthContext'

const useStyles = makeStyles(theme => ({
  root: {
    width: '95vw',
  },
  paper: {
    marginBottom: theme.spacing(2),
  },

  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableCell: {
    textAlign: 'center',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    width: '5%',
    padding: '8px',
  },
  squareImg: {
    '& svg': {
      width: 50,
      height: 50,
    },
  },
}))

function descendingComparator(a, b, orderBy) {
  
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {

  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    
    : (a, b) => -descendingComparator(a, b, orderBy)

}

function stableSort(array, comparator) {
  
 const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
   
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

const headCells = [
  { id: 'Design', numeric: false, disablePadding: true, label: 'Design' },
  { id: 'id', numeric: true, disablePadding: false, label: 'No.' },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Title',
  },
  { id: 'designer', numeric: false, disablePadding: false, label: 'Designer' },
  { id: 'categories', numeric: false, disablePadding: false, label: 'Style' },
  { id: 'categories', numeric: true, disablePadding: false, label: 'Q' },
  {
    id: 'categories',
    numeric: false,
    disablePadding: false,
    label: 'Personal',
  },
  { id: 'categories', numeric: true, disablePadding: false, label: 'Q' },
  {
    id: 'categories',
    numeric: false,
    disablePadding: false,
    label: 'Business',
  },
  { id: 'categories', numeric: true, disablePadding: false, label: 'Q' },
  { id: 'categories', numeric: false, disablePadding: false, label: 'Theme' },
  { id: 'categories', numeric: true, disablePadding: false, label: 'Q' },
  {
    id: 'categories',
    numeric: false,
    disablePadding: false,
    label: 'Trending',
  },
  { id: 'categories', numeric: true, disablePadding: false, label: 'Q' },
  {
    id: 'categories',
    numeric: false,
    disablePadding: false,
    label: 'Occasion',
  },
  { id: 'categories', numeric: true, disablePadding: false, label: 'Q' },
  { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
]

const statuses = [
  { id: 2, value: 'active' },
  { id: 6, value: 'pause' },
]

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow style={{ whiteSpace: 'nowrap' }}>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="center"
            style={{
              fontWeight: 'bold',
              fontSize: '14px',
              width: '5%',
              padding: '8px',
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label == 'Design' || headCell.label == 'Template Name' ? (
              headCell.label
            ) : (
              <>
                {headCell.label}
                <FontAwesomeIcon
                  icon={faSort}
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                  style={{ marginLeft: '10px', fontSize: '16px' }}
                ></FontAwesomeIcon>
              </>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const EnhancedTableToolbar = props => {
  return (
    <Toolbar style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Box
     
        onChange={props.onSearchClick}
        component="input"
        placeholder="Search"
        style={{
          padding: '10px',
          marginRight: '10px',
          borderRadius: '8px',
          width: '258px',
          background: '#F0F0F0',
          borderColor: '#D6D6D6',
        }}
      ></Box>
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
}

const EnhancedTable = () => {
  const classes = useStyles()
  const history = useHistory()
  const [currentTemplate, setCurrentTemplate] = useState([])
  const [allTemplates, setAllTemplates] = useState([])
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState()
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [pauseTemplates, setPauseTemplates] = useState([])

  const [authData] = useContext(AuthContext)

  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  useEffect(() => {
    HttpClient.get('/templates?status=2&onlyParent=true', {
      Authorization: `Bearer ${authData.authToken}`,
    })
      .then(res => {
        setCurrentTemplate([...res, ...pauseTemplates])
        setAllTemplates([...res, ...pauseTemplates])
        setOrderBy(currentTemplate.id)
      })
      .catch(error => {})
  }, [pauseTemplates])

  useEffect(() => {
    HttpClient.get('/templates?status=6&onlyParent=true', {
      Authorization: `Bearer ${authData.authToken}`,
    })
      .then(res => {
        setPauseTemplates(res)
      })
      .catch(error => {})
  }, [])

  const onSearchClick = search => {
    const searchValue = search.target.value
    if (searchValue === '') {
      return setCurrentTemplate(allTemplates)
    } else if (searchValue !== '') {
      toList(searchValue)
    }
  }

  const handleRequestSort = (event, property) => {
   
    const isAsc = orderBy === property && order === 'asc'
    
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const categoryTitle = (categories, typeId) => {
    let titles = []
    for (const value of categories) {
      if (value.category.type === typeId){
        titles.push(value.category.title)
      }
    }
    return titles.join(', ')
  }
  const NewCategoryTitle = (categories, typeId) => {
    return categories.map(
      value => value.category.type === typeId && value.category                                                     
    )
  }
  const categoryScore = (categories, typeId) => {
    for (const value of categories) {
      if (value.category.type === typeId){
        return value.qualityScore
      }
    }
  }
  const titleFilteringFunction=array=>{
    let filteredValue = array.filter(e => e !== '')
    filteredValue = array.map(e => e.title)
    const newFilteredValue= filteredValue.length===0?null: filteredValue.join(', ').toLowerCase()
    return newFilteredValue
  }

  const toList = keyword => {
    const filteredList = []
    for (const [index, template] of allTemplates.entries()) {
      const templateName = template.name?.toLowerCase()
      const templateId = template.id?.toString()
      const DesignerName = template.designer.brandName.toLowerCase()
      const statuses = template.status == 2 ? 'active' : 'pause'

      const StyleValue = NewCategoryTitle(template.categories, 1)
      const StyleQScore = categoryScore(template.categories, 1)

      const personalExpressionValue = NewCategoryTitle(template.categories, 2)
      const personalExpressionQScore = categoryScore(template.categories, 2)

      const businessValue = NewCategoryTitle(template.categories, 3)
      const businessQScore = categoryScore(template.categories, 3)

      const themeValue = NewCategoryTitle(template.categories, 4)
      const themeQScore = categoryScore(template.categories, 4)

      const occasionValue = NewCategoryTitle(template.categories, 5)
      const occasionQScore = categoryScore(template.categories, 5)

      const trendingValue = NewCategoryTitle(template.categories, 6)
      const trendingQScore = categoryScore(template.categories, 6)


      const StyleTitle=titleFilteringFunction(StyleValue)      
      const personalExpressionTitle=titleFilteringFunction(personalExpressionValue)
      const businessTitle=titleFilteringFunction(businessValue)
      const themeTitle=titleFilteringFunction(themeValue)
      const occasionTitle=titleFilteringFunction(occasionValue)
      const trendingTitle=titleFilteringFunction(trendingValue)

      console.log(template.id + ':' +  StyleTitle)
      if (
        statuses.match(keyword.toLowerCase()) != null ||
        templateName.match(keyword.toLowerCase()) != null ||
        templateId.match(keyword) != null ||
        DesignerName.match(keyword.toLowerCase()) != null 
        ||  (StyleTitle!=null?StyleTitle.match(keyword.toLowerCase()):null)!=null
        ||(personalExpressionTitle!=null?personalExpressionTitle.match(keyword.toLowerCase()):null)!=null
        ||(businessTitle!=null?businessTitle.match(keyword.toLowerCase()):null)!=null
        ||(themeTitle!=null?themeTitle.match(keyword.toLowerCase()):null)!=null
        ||(occasionTitle!=null?occasionTitle.match(keyword.toLowerCase()):null)!=null
        ||(trendingTitle!=null?trendingTitle.match(keyword.toLowerCase()):null)!=null
        || (StyleQScore!=null?(StyleQScore.toString()).match(keyword):null)!=null
      
        || (personalExpressionQScore!=null?(personalExpressionQScore.toString()).match(keyword):null)!=null
        || (businessQScore!=null?(businessQScore.toString()).match(keyword):null)!=null
        || (themeQScore!=null?(themeQScore.toString()).match(keyword):null)!=null
        || (occasionQScore!=null?(occasionQScore.toString()).match(keyword):null)!=null
        || (trendingQScore!=null?(trendingQScore.toString()).match(keyword):null)!=null
      ) {

        console.log('keyword found: ' + StyleTitle?.includes(keyword.toLowerCase()) + index)
        filteredList.push(template)
      }
    }
    
    setCurrentTemplate(filteredList)
  }

  const backToPublish = id => {
    history.push(`/publisher?id=${id}&timestamp=${new Date().toISOString()}`)
  }
  const valuesTruncation=array=>{
    return (array.length) > 17 ? array.substr(0,17)+'...' : array
  }

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, currentTemplate.length - page * rowsPerPage)

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar onSearchClick={onSearchClick} />
        <TableContainer>
          <Table
            className="col-md-12"
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={currentTemplate.length}
            />
            <TableBody>
              {stableSort(currentTemplate, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

                .map((name, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`
                  const date = new Date(name.createdAt).toLocaleDateString()
                  var titleArray = []
                  var QScoreArray = []
                  name.categories.map(
                    (value, index) => (
                      titleArray.push(value.category.title),
                      QScoreArray.push(value.qualityScore)
                    )
                  )
                  var max = QScoreArray.reduce(function (a, b) {
                    return Math.max(a, b)
                  })
                  return (
                    <TableRow style={{ position: 'sticky' }}>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        className={classes.tableCell}
                        width="5%"
                      >
                        <Box
                          className={classes.squareImg}
                          dangerouslySetInnerHTML={{
                            __html: name.square.previewImage,
                          }}
                        ></Box>
                      </TableCell>
                      <TableCell className={classes.tableCell} width="5%">
                        <a
                          style={{ color: '#3366BB', cursor: 'pointer' }}
                          onClick={() => backToPublish(name.id)}
                        >
                          {name.id}
                        </a>
                      </TableCell>
                      <TableCell className={classes.tableCell} width="5%">
                        {name.name}
                      </TableCell>
                      <TableCell className={classes.tableCell} width="5%">
                        {name.designer.brandName}
                      </TableCell>
                      <TableCell className={classes.tableCell} width="5%" data-tip={categoryTitle(name.categories, 1)} data-background-color="#b5b3b3" data-offset="{'top':-30}">
                      {valuesTruncation(categoryTitle(name.categories, 1))}
                      <ReactTooltip effect="solid" ></ReactTooltip>
                      </TableCell>
                      <TableCell className={classes.tableCell} width="5%">
                        {categoryScore(name.categories, 1)}
                      </TableCell>
                      <TableCell className={classes.tableCell} width="5%" data-tip={categoryTitle(name.categories, 2)} data-background-color="#b5b3b3" data-offset="{'top':-30}">
                       {valuesTruncation(categoryTitle(name.categories, 2))}
                       <ReactTooltip effect="solid" ></ReactTooltip>
                      </TableCell>
                      <TableCell className={classes.tableCell} width="5%">
                        {categoryScore(name.categories, 2)}
                      </TableCell>
                      <TableCell className={classes.tableCell} width="5%" data-tip={categoryTitle(name.categories, 3)} data-background-color="#b5b3b3" data-offset="{'top':-30}">
                        {valuesTruncation(categoryTitle(name.categories, 3))}
                        <ReactTooltip effect="solid" ></ReactTooltip>
                      </TableCell>
                      <TableCell className={classes.tableCell} width="5%" >
                        {categoryScore(name.categories, 3)}
                      </TableCell>
                      <TableCell className={classes.tableCell} width="5%" data-tip={categoryTitle(name.categories, 4)} data-background-color="#b5b3b3" data-offset="{'top':-30}">
                      {valuesTruncation(categoryTitle(name.categories, 4))}
                      <ReactTooltip  effect="solid" ></ReactTooltip>
                      </TableCell>
                      <TableCell className={classes.tableCell} width="5%">
                        {categoryScore(name.categories, 4)}
                      </TableCell>
                      <TableCell className={classes.tableCell} width="5%" data-tip={categoryTitle(name.categories, 6)} data-background-color="#b5b3b3" data-offset="{'top':-30}">
                      {valuesTruncation(categoryTitle(name.categories, 6))}
                      <ReactTooltip  effect="solid" ></ReactTooltip>
                      </TableCell>
                      <TableCell className={classes.tableCell} width="5%">
                        {categoryScore(name.categories, 6)}
                      </TableCell>
                      <TableCell className={classes.tableCell} width="5%" data-tip={categoryTitle(name.categories, 5)} data-background-color="#b5b3b3" data-offset="{'top':-30}">
                      {valuesTruncation(categoryTitle(name.categories, 5))}
                      <ReactTooltip  effect="solid" ></ReactTooltip>
                      </TableCell>
                      <TableCell className={classes.tableCell} width="5%">
                        {categoryScore(name.categories, 5)}
                      </TableCell>
                      <TableCell className={classes.tableCell} width="5%">
                        {statuses.map(
                          status => status.id === name.status && status.value
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
                
             
            </TableBody>
            
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100,200]}
          component="div"
          count={currentTemplate.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      
    </div>
    
  )
}
export default EnhancedTable
