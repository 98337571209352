import React, { useState } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Box } from "@material-ui/core";
import { AppBar, Toolbar } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import LogoIcon from "../../icons/LogoIcon";

import Authentication from "./Authentication";
import Registration from "./Registration";

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: "inherit",
  },
  // logo: {
  //   fontSize: "4.4rem",
  // },
  live: {
    color: "#007AFF",
    fontWeight: "bold",
  },
  title: {
    color: "#0E1F4A",
    font: "lato",
    fontSize: "57px",
    lineHeight: 1.2,
    fontWeight: "bold",
    marginBottom: '-29px',
  },
  contact: {
    color: "#29406B",
  },
}));

const TopMenu = ({ onAuthenticate }) => {
  const classes = useStyles();
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const [registernDialogOpen, setRegistrationDialogOpen] = useState(false);

  const handleLoginDialogClose = () => {
    setLoginDialogOpen(false);
  };

  const handleRegistrationDialogClose = () => {
    setRegistrationDialogOpen(false);
  };

  return (
    <React.Fragment>
      <AppBar
        position="relative"
        elevation={0}
        title="Be"
        className={classes.header}
      >
        <Container>
          <Toolbar>
            <Grid container justify="flex-end" alignItems="center">
              <Grid item>
                <Button
                  color="primary"
                  size="small"
                  aria-label="Log in"
                  onClick={() => setLoginDialogOpen(true)}
                >
                  Log in
                </Button>
                <Authentication
                  open={loginDialogOpen}
                  onClose={handleLoginDialogClose}
                  onAuthenticate={onAuthenticate}
                />
                {/* <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  aria-label="Get Started"
                  onClick={() => setRegistrationDialogOpen(true)}
                >
                  Get Started
                </Button>
                <Registration
                  open={registernDialogOpen}
                  onClose={handleRegistrationDialogClose}
                /> */}
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth="md">
        <Grid container>
          <Grid item>
            <Typography className={classes.title}>
              <span className={classes.live}> Live</span>
              <span className={classes.contact}>Contact</span>
            </Typography>
            {/* <LogoIcon className={classes.logo} /> */}
            <Box
              mt={6}
              mb={4}
              fontSize="h3.fontSize"
              fontWeight="fontWeightLight"
            >
              <div>Share your contact information</div>
              <div>with anyone, anywhere.</div>
            </Box>

            <Typography variant="h3" component="h1">
              Digital Business Cards
            </Typography>

            <Typography style={{marginTop: '20px', marginLeft: '5px'}} variant="h6" component="h6">
              You&apos;ll love it!
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

TopMenu.propTypes = {
  onAuthenticate: PropTypes.func.isRequired,
};

export default TopMenu;
