import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import Templates from './Templates'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#fff',
  },
}))

const SubCategory = ({ match, location }) => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [category, setCategory] = useState({ title: '' })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    HttpClient.get(`/categories/${match.params.id}`, { Authorization: `Bearer ${authData.authToken}` })
      .then(res => {
        setCategory(res)
        setLoading(false)
      })
      .catch(error => {
        console.log('Error getting categories: ', error)
        setCategory({ title: '' })
        setLoading(false)
      })
  }, [authData.authToken, match.params.id])

  return (
    <Box className={classes.root}>
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <IconButton
            color="inherit"
            aria-label="Back"
            component={Link}
            to={location.state.from}
          >
            <ChevronLeftIcon fontSize="large" />
          </IconButton>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h6">{category.title}</Typography>
        </Grid>
      </Grid>
      <Templates url={`/templates?categories=${match.params.id}`} />
    </Box>
  )
}

SubCategory.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default SubCategory
