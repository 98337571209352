import React, { useContext, useState, useEffect } from 'react'
// import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'

import Style from './Style'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#efefef',
  },
}))

const Styles = () => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [styles, setStyles] = useState([])
  const [loading, setLoading] = useState(true)
  
  useEffect(() => {
    setLoading(true)
    HttpClient.get('/categories/search/2', { Authorization: `Bearer ${authData.authToken}` })
      .then(res => {
        setStyles(res)
        setLoading(false)
      })
      .catch(error => {
        console.log('Error getting styles: ', error)
        setStyles([])
        setLoading(false)
      })
  }, [authData.authToken])

  return (
    <Box className={classes.root}>
      <Box width={1}>
        <IconButton
          color="inherit"
          aria-label="Back"
          component={Link}
          to={'/user/cards/new'}
        >
          <ChevronLeftIcon fontSize="large" />
        </IconButton>
      </Box>
      {styles.map(style =>
        <Style key={style.id} style={style} />
      )}
    </Box>
  )
}

// Styles.propTypes = {
//   history: PropTypes.object.isRequired
// }

export default Styles
