import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const StarFill = props => {
  return (
    <SvgIcon {...props}>
      <path d="M12.4999295,2 C12.9471069,2 13.3495827,2.2644778 13.5263067,2.67305699 L15.9230561,8.24810917 L21.9842252,8.79505755 C22.4306016,8.83502381 22.806801,9.13516871 22.945072,9.55776 C23.0833429,9.98035123 22.9554863,10.4438641 22.6187013,10.7360476 L18.037015,14.7283728 L19.3880004,20.6424215 C19.4870172,21.0772734 19.3171827,21.5269334 18.9541213,21.787749 C18.8631187,21.8532679 18.7644926,21.903594 18.6617684,21.9386137 L18.6717451,21.9946784 L18.5990689,21.9579544 C18.3094144,22.0380788 17.9936109,21.9998304 17.7273074,21.8407719 L12.697,18.854 L12.5,18.75 L12.287,18.862 L7.27255163,21.8425235 C7.0958276,21.9468179 6.89891572,22 6.70024139,22 C6.59879805,22 6.49766617,21.9861326 6.39957865,21.9586161 L6.32825485,21.9946784 L6.33832123,21.9395147 C6.23598149,21.9043443 6.13769396,21.8539348 6.04669899,21.7885452 C5.68347749,21.5269334 5.51380319,21.0772734 5.61265968,20.6433769 L6.96364509,14.729169 L2.38195879,10.7360476 C2.04517374,10.4438641 1.91651612,9.98114735 2.05478706,9.55776 C2.19305795,9.13516871 2.5692574,8.83597916 3.01467241,8.79505755 L9.07680292,8.24810917 L11.4735523,2.67401238 C11.6502763,2.2644778 12.052752,2 12.4999295,2 Z" />
    </SvgIcon>
  )
}

export default StarFill
