import { useState, useEffect } from 'react'
import * as jwt from 'jsonwebtoken'

const useAuthentication = authToken => {
  const [authState, setAuthState] = useState({
    authUser: null,
    designerName: null,
    initializing: true,
  })

  const signOut = () => {
    localStorage.clear('jwtTokenAdmin')
    localStorage.clear('beAdminName')
    setAuthState({
      authUser: null,
      designerName: null,
      initializing: false,
    })
    return true
  }

  useEffect(() => {
    const authUser = jwt.decode(authToken)
    const designerName = localStorage.getItem('beAdminName')
    setAuthState({ authUser, designerName, authToken, initializing: false })
  }, [authToken])

  return [authState, signOut]
}

export default useAuthentication
