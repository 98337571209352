import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Menu, MenuItem } from "@material-ui/core";

const DesignerMenu = (props) => {
  return (
    <Menu
      anchorEl={props.parentEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      id="designer-menu"
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={props.open}
      onClose={() => props.onClose()}
    >
      <MenuItem component={Link} to="/user" onClick={() => props.onClose()}>
        {props.authData.designerName || props.authData.authUser.email}
      </MenuItem>
      <MenuItem onClick={() => props.onLogout()}>Logout</MenuItem>
    </Menu>
  );
};

DesignerMenu.propTypes = {
  authData: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  parentEl: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default DesignerMenu;
