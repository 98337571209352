import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid } from '@material-ui/core'

import HomeIcon from '../../icons/Home'
import SidesIcon from '../../icons/Sides'
import HamburgerIcon from '../../icons/Hamburger'

const useStyles = makeStyles(() => ({
  navItem: {
    color: '#000',
    cursor: 'pointer',
    textDecoration: 'none'
  },
}))

const Navigation = ({ onNavigationChange }) => {
  const classes = useStyles()

  return (
    <Box px={2} paddingBottom={2}>
      <Grid container>
        <Grid item xs={4}>
          <Box
            display="inline-block"
            textAlign="center"
            className={classes.navItem}
            component={Link}
            to={'/user/cards/new'}
          >
            <HomeIcon fontSize="large" />
            <Box>Home</Box>
          </Box>
        </Grid>
        <Grid container item xs={4} justify="center">
          <Box
            display="inline-block"
            textAlign="center"
            className={classes.navItem}
            onClick={() => onNavigationChange(1)}
          >
            <SidesIcon fontSize="large" />
            <Box>Sides</Box>
          </Box>
        </Grid>
        <Grid container item xs={4} justify="flex-end">
          <Box
            display="inline-block"
            textAlign="center"
            className={classes.navItem}
            onClick={() => onNavigationChange(2)}
          >
            <HamburgerIcon fontSize="large" />
            <Box>Add</Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

Navigation.propTypes = {
  onNavigationChange: PropTypes.func.isRequired,
}

export default Navigation
