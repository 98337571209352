import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import Templates from './Templates'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#fff',
  },
}))

const SearchResults = ({ url, onClose }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <IconButton
            color="inherit"
            aria-label="Back"
            onClick={onClose}
          >
            <ChevronLeftIcon fontSize="large" />
          </IconButton>
        </Grid>
      </Grid>
      <Templates url={url} />
    </Box>
  )
}

SearchResults.propTypes = {
  url: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default SearchResults
