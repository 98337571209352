import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  logo: {
    verticalAlign: "middle",
    fontSize: "2.7rem",
  },
  menus: {
   marginRight:"5%"
  },
  menuItem: {
    display: "inline-block",
  },
  listIcon: {
    minWidth: "35px",
  },
  brandBox: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    fontSize: "1.125rem",
  },
  brandFirst: {
    color: "#007AFF",
    fontWeight: "900",
    fontSize: "22px",
    
  },
  brandSecond: {
    color: "#0E1F4A",
    fontWeight: "900",
    fontSize: "22px",
  },
  clickable: {
    cursor: "pointer",
   
  },
  activeTab:{
    borderBottom: "1px solid #979797",
    paddingBottom:"10px",
    cursor: "pointer",
  }
}));

const NavTabs = () => {
  const classes = useStyles();
  const history = useHistory();
  const [registerEl, setRegisterEl] = useState(null);
  const [editEl, setEditEl] = useState(null);
  const [manageTempTab, setManageTempTab] = useState(false)
  const [publisherTab, setPublisherTab] = useState(true)

  const isRegisterMenuOpen = Boolean(registerEl);
  const isEditMenuOpen = Boolean(editEl);

  const handleRegisterMenuOpen = (event) => setRegisterEl(event.currentTarget);
  const handleRegisterMenuClose = () => setRegisterEl(null);

  const handleEditMenuOpen = (event) => setEditEl(event.currentTarget);
  const handleEditMenuClose = () => setEditEl(null);

  const headerTabs = [{id: 1, title: "Template Publisher", value: publisherTab, setValue: setPublisherTab, linkUrl: `/publisher?timestamp=${new Date().toISOString()}` },
                      {id: 2, title: "Template Manager", value: manageTempTab, setValue: setManageTempTab, linkUrl: `/publisher/manage-templates?timestamp=${new Date().toISOString()}` 
                    }
                     ]

  const changeTab = (activeTab) => {
    headerTabs.map(
      (tab) => tab.id === activeTab.id ? tab.setValue(true) : tab.setValue(false)                                                     
    );
    history.push(activeTab.linkUrl)
  }

  useEffect(() => {
    const url = window.location.pathname
    headerTabs.map(
      (tab) => tab.linkUrl === url ? tab.setValue(true) : tab.setValue(false)                                                     
    );
  }, [window.location.pathname])

  return (

    <div className={classes.menus}>
      <MenuItem component={Link} to="/publisher" className={classes.menuItem}>
        <box component="span" className={classes.brandBox}>
          <span className={classes.brandFirst}>Live</span>
          <span className={classes.brandSecond}>Contact</span>
        </box>
      </MenuItem>
      {headerTabs.map((tab, index) => (
        <Box
          component="span"
          ml={5}
          fontSize="18px"
          fontWeight="400"
          color="#0F0F0F"
          className={tab.value ? classes.activeTab : classes.clickable}
          onClick={() => changeTab(tab)}       
        >
         {tab.title}
        </Box>
      ))}
    </div>
  );
};

export default NavTabs;
