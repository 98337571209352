import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

import Text from './Text/index'
import Group from './Group'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#fff',
  },
}))

const Toolbar = props => {
  const classes = useStyles()

  return (
    <React.Fragment>
      <Box className={classes.root}>
        {props.toolbar.type === 'text' || props.toolbar.type === 'textEdit' ?
          <Text
            toolbarData={props.toolbar}
            action={props.action}
            onAddAction={props.onAddAction}
            onCopiedStyleChange={props.onCopiedStyleChange}
          />
          : null
        }
        {(props.toolbar.type === 'group' || props.toolbar.type === 'activeSelection' || props.toolbar.type === 'image') ?
          <Group
            toolbarData={props.toolbar}
            action={props.action}
            onAddAction={props.onAddAction}
          />
          : null
        }
      </Box>
    </React.Fragment>
  )
}

Toolbar.propTypes = {
  toolbar: PropTypes.object.isRequired,
  action: PropTypes.object.isRequired,
  onAddAction: PropTypes.func.isRequired,
  onCopiedStyleChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default Toolbar
