import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { fabric } from 'fabric'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid } from '@material-ui/core'

import HttpClient from '../../../../../../services/HttpClient'
import AuthContext from '../../../../../../contexts/AuthContext'
import SearchBar from '../../../../../SearchBar'

const useStyles = makeStyles(() => ({
  container: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  },
  graphicBox: {
    fill: 'white',
    cursor: 'pointer',
    backgroundColor: 'hsla(0,0%,100%,.07)',
    '& svg': {
      maxWidth: '90%',
      maxHeight: '90%'
    },
    '&:hover': {
      backgroundColor: 'hsla(0,0%,100%,.15)',
    }
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    backgroundColor: 'rgb(171 166 166 / 36%)',
  }
}))

const Graphics = ({ onAddAction }) => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [graphics, setGraphics] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => iconsApi('Beauty Salon'), [])

  const iconsApi = q => {
    HttpClient.get(
      `/3rdParty/icons/${q}?limitToPublicDomain=1`,
      { Authorization: `Bearer ${authData.authToken}` }
    )
      .then(res => setGraphics(res))
      .catch(error => {
        console.log('Error loading graphics: ', error)
        setGraphics([])
      })
  }
  
  const handleAdd = graphicId => {
    HttpClient.get(
      `/3rdParty/icon/${graphicId}`,
      { Authorization: `Bearer ${authData.authToken}` }
    )
      .then(res => {
        fabric.loadSVGFromURL(res.icon_url, (objects, options) => {
          const obj = fabric.util.groupSVGElements(objects, options)
          if(obj.type !== 'group')
            obj.type = 'path'
          onAddAction({ type: 'addObject', value: obj })
        })
      })
      .catch(error => console.log('Error loading graphic: ', error))
  }

  return (
    <React.Fragment>
      <Box mx={1} marginTop={1}>
        <SearchBar query="Beauty Salon" onSearch={iconsApi} />
      </Box>
      <Box flex="1 1 auto" px="2px" paddingTop={1} className={classes.container}>
        <Grid container>
          {graphics.map((graphic, index) => (
            <Grid key={index} item xs={3}>
              <Box
                position="relative"
                width={1}
                pb="100%"
                borderRadius={4}
                boxSizing="border-box"
              >
                <Box
                  position="absolute"
                  left={1}
                  right={1}
                  top={1}
                  bottom={1}
                  borderRadius={4}
                  className={classes.graphicBox}
                  onClick={() => handleAdd(graphic.id)}
                >
                  <img src={graphic.preview_url} alt={graphic.attribution} className={classes.image} />
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </React.Fragment>
  )
}

Graphics.propTypes = {
  onAddAction: PropTypes.func.isRequired
}

export default Graphics
