import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'

import Templates from './Templates'
import StarIcon from '../../icons/Star'
import StarFillIcon from '../../icons/StarFill'

const useStyles = makeStyles(() => ({
  root: {
  },
  svgFormat3: {
    width: '33vh',
    maxWidth: '100%',
    margin: '0 auto',
    '& svg': {
      maxWidth: '100%',
      maxHeight: '100%',
      height: 'auto',
      boxShadow: '0px 0px 10px 5px rgb(0 0 0 / 8%)',
      borderRadius: 'inherit',
    },
  },
  svgFormat2: {
    width: '41vh',
    maxWidth: '100%',
    margin: '0 auto',
    '& svg': {
      maxWidth: '100%',
      maxHeight: '100%',
      height: 'auto',
      boxShadow: '0px 0px 10px 5px rgb(0 0 0 / 8%)',
      borderRadius: 'inherit',
    },
  },
  svgFormat1: {
    width: '100%',
    '& svg': {
      maxWidth: '100%',
      maxHeight: '100%',
      height: 'auto',
      boxShadow: '0px 0px 10px 5px rgb(0 0 0 / 8%)',
      borderRadius: 'inherit',
    },
  },
  thumbnail: {
    '& svg': {
      maxWidth: '100%',
      maxHeight: '100%',
      height: 'auto',
      border: '2px solid #fff',
      boxShadow: '0px 0px 10px 5px rgb(0 0 0 / 8%)',
      borderRadius: 'inherit',
    },
    '& .title': {
      fontSize: '0.85em',
      color: '#868686',
    },
    '&.active': {
      '& svg': {
        border: '2px solid #005bff',
      },
      '& .title': {
        color: '#0a60fe',
      },
    }
  }
}))

const Template = ({ match, history }) => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [format, setFormat] = useState(3)
  const [template, setTemplate] = useState({ favourite: false })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    HttpClient.get(`/templates/${match.params.id}`, { Authorization: `Bearer ${authData.authToken}` })
      .then(res => {
        setTemplate(res)
        setLoading(false)
      })
      .catch(error => {
        console.log('Industries error: ', error)
        setTemplate({})
        setLoading(false)
      })
  }, [authData.authToken, match.params.id])

  const templateFormat = template.formats ? template.formats.find(f => f.type === format) : null
  const vertical = template.formats ? template.formats.find(f => f.type === 3) : ''
  const square = template.formats ? template.formats.find(f => f.type === 2) : ''
  const horizontal = template.formats ? template.formats.find(f => f.type === 1) : ''

  const toggleFavourite = () => {
    if(template.favourite) {
      HttpClient.delete(`/templates/${template.id}/favourite`, { Authorization: `Bearer ${authData.authToken}` })
    } else {
      HttpClient.post(`/templates/${template.id}/favourite`, {}, { Authorization: `Bearer ${authData.authToken}` })
    }
    setTemplate({ ...template, favourite: !template.favourite })
  }

  const handleCreateCard = () => {
    HttpClient.post(
      '/cards',
      { templateId: template.id, formatType: format },
      { Authorization: `Bearer ${authData.authToken}` }
    )
      .then(res => history.push({
        pathname: `/user/cards/${res.card.id}/design?timestamp=${new Date().toISOString()}`,
        state: { from: history.location.pathname }
      }))
      .catch(error => console.log('Error creating create: ', error))
    
  }

  return (
    <React.Fragment>
      <IconButton
        color="inherit"
        aria-label="Back"
        component={Link}
        to={'/user/cards/new'}
      >
        <ChevronLeftIcon fontSize="large" />
      </IconButton>
      <Box
        height="44vh"
        px={3}
      >
        <Box position="relative">
          <Box
            dangerouslySetInnerHTML={{
              __html: templateFormat ? templateFormat.previewImage : ''
            }}
            className={classes[`svgFormat${format}`]}
            onClick={handleCreateCard}
            style={{
              borderRadius: `${templateFormat ? templateFormat.cornerRadius : 0}px`
            }}
          />
        </Box>
      </Box>
      <Box px={3}>
        <Box paddingTop={2} paddingBottom={1}>Choose card size:</Box>
        <Grid container spacing={1}>
          <Grid item xs={3} className={`${classes.thumbnail} ${format === 3 && 'active'}`}>
            <Box
              onClick={() => setFormat(3)}
              dangerouslySetInnerHTML={{
                __html: vertical ? vertical.previewImage : ''
              }}
              style={{
                borderRadius: `${vertical ? vertical.cornerRadius : 0}px`
              }}
            />
            <Box className="title" textAlign="center">vertical</Box>
          </Grid>
          <Grid item xs={3} className={`${classes.thumbnail} ${format === 2 && 'active'}`}>
            <Box
              onClick={() => setFormat(2)}
              dangerouslySetInnerHTML={{
                __html: square ? square.previewImage : ''
              }}
              style={{
                borderRadius: `${square ? square.cornerRadius : 0}px`
              }}
            />
            <Box className="title" textAlign="center">square</Box>
          </Grid>
          <Grid item xs={3} className={`${classes.thumbnail} ${format === 1 && 'active'}`}>
            <Box
              onClick={() => setFormat(1)}
              dangerouslySetInnerHTML={{
                __html: horizontal ? horizontal.previewImage : ''
              }}
              style={{
                borderRadius: `${horizontal ? horizontal.cornerRadius : 0}px`
              }}
            />
            <Box className="title" textAlign="center">horizontal</Box>
          </Grid>
          <Grid
            container
            item xs={3}
            justify="flex-end"
            alignContent="flex-start"
          >
            <IconButton
              color={template.favourite ? 'primary' : 'inherit'}
              aria-label="Favourite"
              onClick={toggleFavourite}
            >
              {template.favourite ?
                <StarFillIcon />
                :
                <StarIcon />
              }
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Box px={2} paddingTop={3}>
        <Typography variant="h6">Similar results:</Typography>
      </Box>
      {template.id ?
        <Templates url={`/templates/${template.id}/similar`} />
        : null  
      }
    </React.Fragment>
  )
}

Template.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default Template
