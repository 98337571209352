import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Undo = props => {
  return (
    <SvgIcon {...props}>
      <path d="M14.0803571,4.25860335 L8.3125,4.25860335 L8.3125,0.818944126 C8.3125,0.516909288 8.1403125,0.239402496 7.86451562,0.0969023284 C7.58871875,-0.0455978392 7.2540558,-0.0299965278 6.99378125,0.137523066 L0.377709821,4.39614877 C0.141736607,4.5480261 0,4.80374838 0,5.07756983 C0,5.35139127 0.141736607,5.60711355 0.377709821,5.75899089 L6.99378125,10.0176166 C7.2544375,10.1853819 7.58910045,10.2005737 7.86451562,10.0582373 C8.1403125,9.91573716 8.3125,9.63823036 8.3125,9.33619553 L8.3125,5.89653631 L14.0803571,5.89653631 C17.3542946,5.89653631 20.0178571,8.46825485 20.0178571,11.6293017 C20.0178571,14.7903485 17.3542946,17.362067 14.0803571,17.362067 C13.6118884,17.362067 13.2321429,17.7287183 13.2321429,18.1810335 C13.2321429,18.6333487 13.6118884,19 14.0803571,19 C18.2897054,19 21.7142857,15.6935047 21.7142857,11.6293017 C21.7142857,7.56509862 18.2897054,4.25860335 14.0803571,4.25860335 Z M6.61607143,7.80595666 L2.377375,5.07756983 L6.61607143,2.349183 L6.61607143,7.80595666 Z" />
    </SvgIcon>
  )
}

export default Undo
