import React, { useContext, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
// import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import Toolbar from './Toolbar'
import Navigation from './Navigation'
import SidesMenu from './SidesMenu'
// import DefaultToolbar from './Toolbar/Default'
import Menu from './Menu'
import Side from './Side'
// import TextSyling from './Toolbar/TextStyle'
import Panel from './Panel'
import UndoIcon from '../../icons/Undo'
import RedoIcon from '../../icons/Redo'
import DownloadIcon from '../../icons/Download'
import ExportIcon from '../../icons/Export'

const useStyles = makeStyles(theme => ({
  header: {
    // background: 'linear-gradient(to right, rgba(255,153,0, 1) 25%, rgba(149,87,239, 0.8))',
    backgroundColor: '#000',
  },
  sides: {
    overflowX: 'auto',
    overflowY: 'hidden',
    scrollBehavior: 'smooth',
    transformOrigin: 'bottom',
    transition: 'all .2s ease-in-out',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  button: {
    color: '#fff',
    minWidth: '44px',
  },
  btnLabel: {
    flexDirection: 'column',
  },
  btnStartIcon: {
    margin: 0,
  },
  icon: {
    fontSize: '2em !important'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const initialCard = {
  id: 'card-1',
  name: 'Name Your Card',
  type: 1,
  cornerRadius: 8,
  colors: [],
  // sides: [
  //   {
  //     id: 'card-1-1',
  //     position: 0,
  //     svg: null,
  //     json: null,
  //     undo: 0,
  //     redo: 0
  //   },
  // ]
}

const Editor = ({ match }) => {
  const classes = useStyles()
  const sidesBoxRef = useRef(null)
  const [authData] = useContext(AuthContext)
  const [dataFields, setDataFields] = useState([])
  const [activeSide, setActiveSide] = useState(0)
  const [card, setCard] = useState(initialCard)
  const [toolbar, setToolbar] = useState({ type: 'default' })
  const [action, setAction] = useState({})
  const [copiedStyle, setCopiedStyle] = useState({})
  const [showTextPanel, setShowTextPanel] = useState(false)
  const [navigation, setNavigation] = useState(null)
  const [panel, setPanel] = useState(null)
  const [publishing, setPublishing] = useState(false)

  useEffect(() => {
    if( match.params.id !== undefined ) {
      Promise.all([
        HttpClient.get(`/cards/${match.params.id}`, { Authorization: `Bearer ${authData.authToken}` }),
        HttpClient.get(`/cards/${match.params.id}/data_fields`, { Authorization: `Bearer ${authData.authToken}` })
      ])
        .then(([resCard, resDataFields]) => {
          setDataFields(resDataFields)
          if(resCard.colors ===  null)
            resCard.colors = []
          if(resCard.cornerRadius ===  null)
            resCard.cornerRadius = 8
          setActiveSide(resCard.sides[0].id)
          setCard(resCard)
        })
        .catch(error => console.log('Error fetch card and data fields: ', error))
    }
  }, [authData.authToken, match.params.id])

  // const handleRadiusUpdate = radius => {
  //   if( Number.isInteger(card.id) ) {
  //     HttpClient.put(
  //       `/cards/${card.id}`,
  //       {
  //         type: card.type,
  //         profileId: card.profileId,
  //         cornerRadius: radius
  //       },
  //       { Authorization: `Bearer ${authData.authToken}` }
  //     )
  //   }
  //   setCard({ ...card, cornerRadius: radius })
  // }

  const handleSideActivation = sideId => {
    setAction({})
    setActiveSide(sideId)
  }

  const handleCreateSide = () => {
    const newSide = {
      position: card.sides.length,
      svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" xml:space="preserve"><rect x="0" y="0" width="100%" height="100%" fill="#ffffff"></rect>↵</svg>',
      json: '{"version":"3.6.6","objects":[],"background":"#ffffff"}',
      settings: card.sides[0].settings,
    }
    HttpClient.post(
      `/cards/${card.id}/sides`,
      newSide,
      { Authorization: `Bearer ${authData.authToken}` }
    ).then(res => {
      const _sides = [...card.sides]
      _sides.push(res)
      setActiveSide(res.id)
      setCard({ ...card, sides: _sides })
      sidesBoxRef.current.scrollTo(sidesBoxRef.current.scrollWidth, 0)
    })
  }

  const handleDuplicateSide = () => {
    HttpClient.put(
      `/cards/${card.id}/sides/${activeSide}/clone`,
      {},
      { Authorization: `Bearer ${authData.authToken}` }
    ).then(res => {
      const _sides = [...card.sides, res]
      setActiveSide(res.id)
      setCard({ ...card, sides: _sides })
    })
  }

  const handleDeleteSide = () => {
    const _sides = [...card.sides].filter(s => s.id !== activeSide)
    HttpClient.delete(
      `/cards/${card.id}/sides/${activeSide}`,
      { Authorization: `Bearer ${authData.authToken}` }
    ).then(() => {
      setCard({ ...card, sides: _sides })
    })
  }

  const handlePanelChange = mode => {
    setNavigation(null)
    setPanel(mode)
  }

  const hadleToolbarChange = toolbarData => {
    if(toolbarData.type === 'text') {
      setShowTextPanel(true)
    } else {
      setShowTextPanel(false)
    }
    setToolbar(toolbarData)
  }

  const handlePublish = e => {
    e.preventDefault()
    e.stopPropagation()
    setPublishing(true)
    HttpClient.put(
      `/cards/${card.id}/publish`,
      {},
      { Authorization: `Bearer ${authData.authToken}` }
    )
      .then(() => {
        setPublishing(false)
        // const userAgent = window.navigator.userAgent.toLowerCase()
        // const ios = /iphone|ipod|ipad/.test( userAgent )
        if(window.Print !== undefined)
          window.Print.postMessage(`publish-${card.profileId}`)
      })
      .catch(error => {
        setPublishing(false)
        console.log('Error publish card: ', error)
      })
  }

  // const handleClose = () => {
  //   // const userAgent = window.navigator.userAgent.toLowerCase()
  //   // const ios = /iphone|ipod|ipad/.test( userAgent )
  //   if(window.Print !== undefined)
  //     window.Print.postMessage('close')
  // }

  const handleSave = () => {
    if(window.Print !== undefined)
      window.Print.postMessage('close-drafts')
  }

  const handleAddActionFromPanel = action => {
    setPanel(null)
    setAction(action)
  }

  return (
    <React.Fragment>
      {publishing ? 
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
        :
        null
      }
      <Box
        position="fixed"
        top={0}
        width={1}
        zIndex="1"
        color="#fff"
        className={classes.header}
      >
        <Box m={1}>
          <Grid container alignItems="center">
            <Grid container item xs={6}>
              <Button
                classes={{ root: classes.button, label: classes.btnLabel, startIcon: classes.btnStartIcon }}
                startIcon={<UndoIcon className={classes.icon} />}
              >
                <Box display="inline" fontSize="0.6em">Undo</Box>
              </Button>
              <Button
                classes={{ root: classes.button, label: classes.btnLabel, startIcon: classes.btnStartIcon }}
                startIcon={<RedoIcon className={classes.icon} />}
              >
                <Box display="inline" fontSize="0.6em">Redo</Box>
              </Button>
            </Grid>
            <Grid container item xs={6} justify="flex-end">
              <Button
                classes={{ root: classes.button, label: classes.btnLabel, startIcon: classes.btnStartIcon }}
                startIcon={<DownloadIcon className={classes.icon} />}
                onClick={handleSave}
              >
                <Box display="inline" fontSize="0.6em">Save</Box>
              </Button>
              <Button
                classes={{ root: classes.button, label: classes.btnLabel, startIcon: classes.btnStartIcon }}
                startIcon={<ExportIcon className={classes.icon} />}
                onClick={handlePublish}
              >
                <Box display="inline" fontSize="0.6em">Publish</Box>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {Number.isInteger(card.id) ?
        <React.Fragment>
          <Box width={1} height={1} paddingTop="72px" bgcolor="#ecf0f3">
            <Box display="flex" height={1} flexDirection="column">
              <Box flex="1 1 45%">
                {/* <Toolbar
                  toolbar={toolbar}
                  cornerRadius={card.cornerRadius}
                  onRadiusUpdate={handleRadiusUpdate}
                  action={action}
                  onAddAction={setAction}
                /> */}
                <Box
                  ref={sidesBoxRef}
                  display="flex"
                  width="100vw"
                  className={classes.sides}
                  style={{ transform: `scale(${navigation === 1 ? 0.65 : 1})` }}
                >
                  {card.sides.sort((s1, s2) => (s1.position - s2.position)).map(side => (
                    <Box
                      key={side.id}
                      flex="0 0 100%"
                    >
                      <Side
                        sidesLength={card.sides.length}
                        cardId={card.id}
                        formatType={card.type}
                        cornerRadius={card.cornerRadius}
                        side={side}
                        onCreateSide={handleCreateSide}
                        dataFields={dataFields}
                        removeAble={false}
                        action={side.id === activeSide ? action : {}}
                        copiedStyle={copiedStyle}
                        onCopiedStyleChange={setCopiedStyle}
                        onToolbarChange={hadleToolbarChange}
                        navigation={navigation}
                        active={activeSide === side.id}
                        onActivate={handleSideActivation}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
              {navigation === 1 ?
                <Box flex="1 1 10%" display="flex" alignItems="center" px="25%">
                  <SidesMenu
                    sidesLength={card.sides.length}
                    onNavigationChange={setNavigation}
                    onCreateSide={handleCreateSide}
                    onDuplicateSide={handleDuplicateSide}
                    onDeleteSide={handleDeleteSide}
                  />
                  {/* <Box width={1} paddingTop="8%">
                    <DefaultToolbar cornerRadius={card.cornerRadius} onRadiusUpdate={handleRadiusUpdate} />
                  </Box> */}
                </Box>
                : null
              }
              
              {(!showTextPanel && panel === null) || navigation === 1 ?
                <Box flex="1 1 10%" display="flex" alignItems="center">
                  {/* <Menu onPanelChange={setPanel} onAddAction={setAction} /> */}
                  {navigation === 1 ?
                    <Grid container>
                      <Grid item xs={2}></Grid>
                      <Grid item xs={4}>
                        <Button onClick={() => setNavigation(null)}>Cancel</Button>
                      </Grid>
                      <Grid container item xs={4} justify="flex-end">
                        <Button onClick={() => setNavigation(null)}>Done</Button>
                      </Grid>
                      <Grid item xs={2}></Grid>
                    </Grid>
                    : null
                  }
                </Box>
                : null
              }
              {navigation === 2 ?
                <Menu
                  onPanelChange={handlePanelChange}
                  onAddAction={setAction}
                  onClose={() => setNavigation(null)} />
                :
                null
              }
              {navigation !== 1 ?
                <React.Fragment>
                  {toolbar.type !== 'textEdit' ?
                    <Navigation onNavigationChange={setNavigation} />
                    : null
                  }
                  <Box position="fixed" width={1} bottom="0">
                    <Toolbar
                      toolbar={toolbar}
                      action={action}
                      onAddAction={setAction}
                      onCopiedStyleChange={setCopiedStyle}
                      onClose={() => setNavigation(null)}
                    />
                  </Box>
                </React.Fragment>
                : null
              }
            </Box>
            {(panel !== null) ?
              <Panel
                panel={panel}
                cardId={card.id}
                onAddAction={handleAddActionFromPanel}
                onClose={() => setPanel(null)}
              />
              : null
            }
          </Box>
        </React.Fragment>
        : null
      }
    </React.Fragment>
  )
}

Editor.propTypes = {
  match: PropTypes.object.isRequired
}

export default Editor
