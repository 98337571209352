import React, { useState, useEffect } from "react";
import RadioStyle from "../../../components/RadioButton/RadioButton.module.css";
import {
  Box,
  Grid,
  Link,
  Typography,
  Divider,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { makeStyles } from "@material-ui/core/styles";
import { first } from "lodash/fp";
import CloseIcon from "@material-ui/icons/Close";

import Autocomplete from "../../Autocomplete";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 600,
  },

  contentWrapper: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "start",
  },

  dialogTitleText: {
    backgroundColor: "#A3CED1",
    color: "#090909",
    borderColor: "#979797",
  },
  typoText: {
    color: "#0D0D0D",
    fontWeight: 500,
    fontSize: 18,
  },
  contentWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },

  feedbackArea: {
    padding: "10px",
    resize: "none",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  submitButton: {
    marginRight: "27px",
    marginBottom: "10px",
    marginTop: "-10px",
    borderColor: "#DF8E8E",
    color: "black",
  },
}));
const ListModal = (props) => {
  const classes = useStyles();
  const { open, setOpen, feedbackValue, onReject, onInputChange } = props;

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: 600,
            height: "auto",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          className={classes.dialogTitleText}
        >
          Feedback
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className={classes.contentWrapper}
          >
            <div>
              <Box display="flex" flexDirection="column" mt={1}>
                <Grid md={11}>
                  <textarea
                    cols={64}
                    rows={8}
                    placeholder="Enter feedback here..."
                    className={classes.feedbackArea}
                    onChange={onInputChange}
                    name="feedback"
                    defaultValue={feedbackValue}
                  ></textarea>
                </Grid>
              </Box>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.submitButton}
            variant="outlined"
            color="primary"
            onClick={onReject}
            disabled={!feedbackValue}
          >
            Send for Revision
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ListModal;
