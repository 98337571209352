import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { first } from "lodash/fp";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

import HttpClient from "../../services/HttpClient";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField, Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import MuiAlert from "@material-ui/lab/Alert";

import ReConfirmation from "./ReConfirmation";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "60vh",
  },
  actions: {
    marginTop: "2%",
    marginBottom: "20%",
    justifyContent: "flex-end",
  },
  live: {
    color: "#007AFF",
    fontWeight: "bold",
  },
  title: {
    color: "#0E1F4A",
    font: "lato",
    fontSize: "240%",
    lineHeight: 1.2,
    fontWeight: "bold",
    marginTop:40,
    marginBottom: '-26px',
  },
  contact: {
    color: "#29406B",
  },
}));

const initialAuthData = {
  email: "",
  password: "",
};

const initialErrorMessages = {
  email: "",
  password: "",
};
const override = css`
  margin-left: 5px;
  width: 0.8rem;
  height: 0.8rem;
  font-size: 12px;
  margin-top: 2px;
`;

const Authentication = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [designer, setDesigner] = useState(initialAuthData);
  const [errors, setErrors] = useState(initialErrorMessages);
  const [authError, setAuthError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setErrors(initialErrorMessages);
    setAuthError(null);
  }, [props.open]);

  useEffect(() => {
    localStorage.getItem("jwtTokenAdmin") && history.push(`/publisher?timestamp=${new Date().toISOString()}`);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const _errors = { ...errors, ...initialErrorMessages };
    let isValid = true;
    if (designer.email === "") {
      isValid = false;
      _errors.email = "Required";
    }
    if (designer.password === "") {
      isValid = false;
      _errors.password = "Password can't be blank";
    }

    if (isValid) {
      setLoading(true);
      HttpClient.post("/login", designer)
        .then((res) => {
          setLoading(false);
          if (res.account.admin){
          localStorage.setItem("jwtTokenAdmin", res.token); 
          localStorage.setItem(
            "beAdminName",
            first(res.account.profiles).firstName
          );
          props.onAuthenticate();
          }
          else setAuthError("This is not an admin account");
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status === 401) {
            error.response.text().then((response) => {
              setAuthError(JSON.parse(response).error.message);
            });
          }
          if (error.response.status === 422) {
            setAuthError("Invalid email or password.");
          }
        });
    } else {
      setErrors(_errors);
    }
  };

  return (
    <React.Fragment>
      {authError !== null && authError === "Error : Email is not verified" && (
        <ReConfirmation
          email={designer.email}
          onAuthErrorChange={setAuthError}
        />
      )}
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="sign-in-dialog-title"
        classes={{ paper: classes.root }}
      >
        <Grid container>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <DialogTitle id="registration-dialog-title">
              <Typography className={classes.title}>
                <span className={classes.live}> Live</span>
                <span className={classes.contact}>Contact</span>
              </Typography>
            </DialogTitle>
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Typography
                  variant="h6"
                  component="h2"
                  color="textPrimary"
                  gutterBottom
                >
                  Log in to your account:
                </Typography>
                {authError !== null ? (
                  <MuiAlert severity="error">{authError}</MuiAlert>
                ) : null}
                <TextField
                  id="email"
                  label="E-mail"
                  type="email"
                  autoComplete="username"
                  value={designer.email}
                  onChange={(e) =>
                    setDesigner({ ...designer, email: e.currentTarget.value })
                  }
                  margin="normal"
                  fullWidth
                  required
                  error={errors.email !== ""}
                  helperText={errors.email}
                />
                <TextField
                  id="password"
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  value={designer.password}
                  onChange={(e) =>
                    setDesigner({
                      ...designer,
                      password: e.currentTarget.value,
                    })
                  }
                  margin="normal"
                  fullWidth
                  required
                  error={errors.password !== ""}
                  helperText={errors.password}
                />
              </DialogContent>
              <DialogActions className={classes.actions}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  Log in
                  {loading && (
                    <ClipLoader css={override} size="20" color="white" />
                  )}
                </Button>
              </DialogActions>
            </form>
          </Grid>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
};

Authentication.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAuthenticate: PropTypes.func.isRequired,
};

export default Authentication;
