import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Star = props => {
  return (
    <SvgIcon {...props}>
      <path d="M5.37170445,24 C5.10875852,24 4.84764367,23.914399 4.62479884,23.7462542 C4.20968856,23.4323201 4.01577508,22.892728 4.12875392,22.3720523 L5.67273726,15.2750028 L0.43652433,10.4832572 C0.0516271332,10.132637 -0.0954101438,9.57737684 0.0626137788,9.06931197 C0.220637654,8.56220241 0.650579882,8.20317497 1.15962562,8.15406904 L8.08777474,7.49773101 L10.8269169,0.808814854 C11.0288872,0.317373362 11.4888595,0 11.9999195,0 C12.5109794,0 12.9709516,0.317373362 13.1729219,0.807668388 L15.9120641,7.49773101 L22.8391146,8.15406904 C23.349259,8.20202855 23.7792012,8.56220241 23.9372251,9.06931197 C24.095249,9.57642148 23.9491273,10.132637 23.5642301,10.4832572 L18.3280171,15.2740474 L19.8720005,22.3709059 C19.9851625,22.892728 19.7910659,23.4323201 19.3761387,23.7452988 C18.962127,24.0582775 18.4100504,24.0823528 17.9740656,23.8089263 L11.9999195,20.0835629 L6.02577329,23.8110281 C5.82380298,23.9361814 5.59876083,24 5.37170445,24 Z M11.9999195,18.4787351 C12.2269758,18.4787351 12.4518348,18.5423626 12.6539882,18.6675159 L18.2921276,22.1863287 L16.8349384,15.4878589 C16.7309319,15.01113 16.8860261,14.5133831 17.2390621,14.1908508 L22.1832146,9.66622613 L15.6419769,9.04638313 C15.171018,9.00148081 14.7659788,8.69251469 14.5819532,8.23967005 L11.9999195,1.92736012 L9.41477276,8.24062546 C9.23276147,8.69041286 8.82772215,8.99937898 8.35786197,9.04428131 L1.81570868,9.66412431 L6.75967808,14.1887489 C7.11381276,14.5122367 7.26872382,15.0090282 7.16380182,15.4869035 L5.70771127,22.1851822 L11.3458506,18.6675159 C11.5478209,18.5423626 11.7728631,18.4787351 11.9999195,18.4787351 Z" />
    </SvgIcon>
  )
}

export default Star
