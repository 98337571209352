import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import HttpClient from "../../services/HttpClient";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
// import LogoIcon from '../../icons/LogoIcon'

const useStyles = makeStyles(() => ({
  root: {
    minHeight: "60vh",
  },
  // logo: {
  //   fontSize: '4.4rem',
  //   marginTop: '16%',
  //   marginBottom: '3%',
  // },
  actions: {
    marginTop: "2%",
    marginBottom: "20%",
    justifyContent: "flex-end",
  },
}));

const Confirmation = ({ history, match }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    let didCancel = false;
    HttpClient.get(`/account/confirmation?verificationCode=${match.params.id}`)
      .then(() => {
        if (!didCancel) {
          setVerified(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("Error cofirmation: ", error);
        setLoading(false);
      });
    return () => {
      didCancel = true;
    };
  }, [match.params.id]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={true}
      onClose={() => history.push("/")}
      aria-labelledby="sign-in-dialog-title"
      classes={{ paper: classes.root }}
    >
      <Grid container>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <DialogTitle id="registration-dialog-title">
            <Typography className={classes.title}>
              <span className={classes.live}> Live</span>
              <span className={classes.contact}>Contact</span>
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography
              variant="h6"
              component="h2"
              color="textPrimary"
              gutterBottom
            >
              Account Activation
            </Typography>
            <DialogContentText>
              {loading && (
                <Typography variant="h1">
                  <Skeleton />
                </Typography>
              )}
              {!loading ? (
                verified ? (
                  <div>
                    Your account is being activated successfully now. You can
                    now log in to your account.
                  </div>
                ) : (
                  <div>
                    The activation link is invalid or already being used.
                  </div>
                )
              ) : null}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button
              variant="contained"
              size="small"
              onClick={() => history.push("/")}
            >
              Close
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  );
};

Confirmation.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default Confirmation;
