import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import DataTable from "./DataTable";
const useStyles = makeStyles((theme) => ({
  paddingX: {
    padding: "0 15px",
  },
  mainCardWrapper: {
    marginTop: "50px",
  },
}));

const TemplatePublisher = () => {
  const classes = useStyles();
  return (
    <div className={classes.mainCardWrapper}>
      <DataTable />
    </div>
  );
};

export default TemplatePublisher;
