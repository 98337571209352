import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'

import TopMenu from './topbar/TopMenu'
import TemplateSubmitForm from './Dashboard/TemplateSubmitForm'
import TemplatePublisher from './Dashboard/TemplatePublisher'
const DesignerPanel = ({ match }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      height={1}
      style={{ overflow: 'auto' }}
    >
      <Box flex="0 1 auto">
        <TopMenu />
      </Box>
      <Box display="flex" justifyContent="center" alignItems="start">
        <Route
          exact
          path={`${match.path}/manage-templates`}
          component={TemplatePublisher}
        />
      </Box>
      <Box flex="1 1 auto" display="flex" overflow="hidden">
        <Route exact path={`${match.path}`} component={TemplateSubmitForm} />
      </Box>
    </Box>
  )
}

DesignerPanel.propTypes = {
  match: PropTypes.object.isRequired,
}

export default DesignerPanel
