import React, { useState, useContext } from "react";

import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import { Box,MenuItem} from "@material-ui/core";
import { get } from "lodash/fp";

import AuthContext from "../../../contexts/AuthContext";
import NavTabs from "./NavTabs";
import DesignerMenu from "./DesignerMenu";


const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: 0,
   
   display: "flex",
   justifyContent: "space-around"

  },
  avatar: {
   
    marginRight: theme.spacing(1),
    height:"60px",
    width:"60px",
    marginBottom:"10px",
    marginTop:"10px",
   

  },
  nameText:{
    fontSize: "18px",
    fontWeight: "400",
  },

  
}));

const TopMenu = () => {
  const classes = useStyles();
  const [authData, signOut] = useContext(AuthContext);
  const [accountEl, setAccountEl] = useState(null);

  const handleAccountMenuClose = () => setAccountEl(null);
  const getInitial = (text) => {
    return text.charAt(0);
  };
  return (
    <React.Fragment>
      <AppBar color="inherit" position="relative" title="LiveContact">
       <Container>
          <Toolbar variant="dense" className={classes.toolbar}>
            <NavTabs />
          
            
            <MenuItem onClick={(event) => setAccountEl(event.currentTarget)} >
              <Avatar
                alt={authData.authUser.name || authData.authUser.email}
                className={classes.avatar}
              >
                {authData.designerName && getInitial(get("designerName", authData))}
              </Avatar>

              <pre className={classes.nameText}
             >
                CS Manager:{" "}
              </pre>
              <p className={classes.nameText}
              >
                {authData.designerName || authData.authUser.email}
              </p>
            </MenuItem>
          </Toolbar>
          </Container>
      </AppBar>
      <DesignerMenu
        parentEl={accountEl}
        open={Boolean(accountEl)}
        onClose={handleAccountMenuClose}
        authData={authData}
        onLogout={signOut}
      />
    </React.Fragment>
  );
};

export default TopMenu;
