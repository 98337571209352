import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Export = props => {
  return (
    <SvgIcon {...props}>
      <g transform="translate(3.000000, 4.000000)" fill="#FFFFFF" fillRule="nonzero">
        <path d="M13.5364207,1.25653138 L16.8526778,1.25653138 L9.27109124,8.83967994 C9.02557828,9.08512484 9.02557828,9.48443073 9.27109124,9.72987564 C9.39201553,9.85076641 9.55324791,9.91304348 9.7144803,9.91304348 C9.87571268,9.91304348 10.0369451,9.85076641 10.1578694,9.72987564 L17.7431203,2.14672708 L17.7431203,5.46206498 C17.7431203,5.81008387 18.0252769,6.09216234 18.3697279,6.09216234 C18.7178433,6.09216234 19,5.81008387 19,5.46206498 L19,0.630097368 C19,0.282078473 18.7178433,0 18.3697279,0 L13.5364207,0 C13.1883053,0 12.9061487,0.282078473 12.9061487,0.630097368 C12.909813,0.974452907 13.1883053,1.25653138 13.5364207,1.25653138 Z" />
        <path d="M3.98918588,19 L15.0108141,19 C17.2128137,19 19,17.2093023 19,15.0116279 L19,9.82170543 C19,9.45348837 18.7014895,9.15891473 18.3331973,9.15891473 C17.9649051,9.15891473 17.6702714,9.45736434 17.6702714,9.82170543 L17.6702714,15.0077519 C17.6702714,16.4728682 16.4762293,17.6666667 15.0108141,17.6666667 L3.98918588,17.6666667 C2.52377066,17.6666667 1.32972863,16.4728682 1.32972863,15.0077519 L1.32972863,6.25581395 L1.32972863,3.99224806 C1.32972863,2.52713178 2.52377066,1.33333333 3.98918588,1.33333333 L9.13364619,1.33333333 C9.50193838,1.33333333 9.79657213,1.03488372 9.79657213,0.666666667 C9.79657213,0.298449612 9.49806162,0 9.13364619,0 L3.98918588,0 C1.78718629,0 0,1.79069767 0,3.98837209 L0,6.25193798 L0,15.0077519 C0,17.2093023 1.79106305,19 3.98918588,19 Z" />
      </g>
    </SvgIcon>
  )
}

export default Export
