import React from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import Category from './Category'
import Templates from './Templates'

const Home = () => {
  return (
    <React.Fragment>
      <Category category={{ id: 2, name: 'Styles' }} />
      <Category category={{ id: 1, name: 'Personal Expression' }} />
      <Category category={{ id: 1, name: 'Business' }} />
      <Box px={2} paddingTop={1}>
        <Typography variant="h6">Trending</Typography>
      </Box>
      <Templates url="/templates" />
    </React.Fragment>
  )
}

Home.propTypes = {
  history: PropTypes.object.isRequired
}

export default Home
