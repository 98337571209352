import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty } from "lodash/fp";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 600,
  },
  noRecord: {
    color: "#0D0D0D",
    fontWeight: 500,
    fontSize: 18,
    textAlign: "center",
  },
}));
const NoRecordFound = (props) => {
  const classes = useStyles();
  const { data } = props;

  return (
    <div>
      <Typography className={classes.noRecord}>No record found.</Typography>
    </div>
  );
};

export default NoRecordFound;
