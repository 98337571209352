import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Hamburger = props => {
  return (
    <SvgIcon {...props}>
      <rect x="0.5" y="1.5" width="23" height="4" rx="2" fill="none" strokeWidth="1.5" stroke="currentColor" />
      <rect x="0.5" y="8.5" width="23" height="4" rx="2" fill="none" strokeWidth="1.5" stroke="currentColor" />
      <rect x="0.5" y="15.5" width="23" height="4" rx="2" fill="none" strokeWidth="1.5" stroke="currentColor" />
    </SvgIcon>
  )
}

export default Hamburger
