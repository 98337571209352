import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { fabric } from 'fabric'

import { makeStyles } from '@material-ui/core/styles'
import { Box, GridList, GridListTile } from '@material-ui/core'
import Button from '@material-ui/core/Button'

import RImage from './Image'

const useStyles = makeStyles(() => ({
  tab: {
    textAlign: 'right',
    color: '#7c7c7c',
  },
  container: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  },
}))

const Backgrounds = ({ onAddAction }) => {
  const classes = useStyles()
  const [images, setImages] = useState([])
  let fileReader

  useEffect(() => {
    pexels('sports')
  }, [])

  const pexels = q => {
    fetch(`https://api.pexels.com/v1/search?query=${q}&per_page=40`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: process.env.REACT_APP_PEXELS_API_KEY
      }
    })
      .then(res => res.json())
      .then(res => setImages(res.photos))
      .catch(() => setImages([]))
  }

  // const pixaybay = q => {
  //   fetch(`https://pixabay.com/api/?key=${process.env.REACT_APP_PIXABAY_API_KEY}&q=${q}&image_type=photo&pretty=true&page=1&per_page=24&safesearch=true`)
  //     .then(res => res.json())
  //     .then(res => setImages(res.hits))
  //     .catch(() => setImages([]))
  // }

  // const unsplash = () => {
  //   fetch('https://api.unsplash.com/search/photos/?client_id=d180e1acc68141f91058bc9098f8bfcbfad8d2297a1568ee11e86b66ebc123ca&query=sports&per_page=24&page=1')
  //     .then(res => res.json())
  //     .then(res => setImages(res.results))
  //     .catch(err => {
  //       console.log('Error!', err)
  //       setImages([])
  //     })
  // }

  const handleMyImageChosen = file => {
    fileReader = new FileReader()
    fileReader.onload = handleMyImageAdd
    fileReader.readAsDataURL(file)
  }

  const handleMyImageAdd = event => {
    let imgObj = new Image()
    imgObj.src = event.target.result
    imgObj.onload = () => {
      const oImg = new fabric.Image(imgObj, {
        crossOrigin: 'anonymous'
      })
      onAddAction({
        type: 'addObject', property: 'image', value: oImg
      })
    }
  }

  return (
    <React.Fragment>
      <Box pt={1} pb={1.7} width="60%" margin="0 auto">
        <Box
          display="inline-block"
          width={1/2}
          p="2% 7% 0 0"
          className={classes.tab}
        >
          <Button component="label">
            My images
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={event => handleMyImageChosen(event.target.files[0])}
            />
          </Button>
        </Box>
        <Box
          display="inline-block"
          width={1/2}
          p="2% 0 0 7%"
        >
          Trending
        </Box>
      </Box>
      <Box height={1} px="2px" className={classes.container}>
        <GridList cellHeight={64} cols={4}>
          {images.map((image, index) => (
            <GridListTile key={index} cols={1}>
              <RImage image={image} onAddAction={onAddAction} />
            </GridListTile>
          ))}
        </GridList>
      </Box>
    </React.Fragment>
  )
}

Backgrounds.propTypes = {
  onAddAction: PropTypes.func.isRequired,
}

export default Backgrounds
