import React from "react";
import Box from "@material-ui/core/Box";
import RadioStyle from "./RadioButton.module.css";
export default function RadioButtons(props) {
  return (
    <React.Fragment>
      <Box className={RadioStyle.radio} mr={4}>
        <input
          checked={parseFloat(props.defaultValue, 10) === 0}
          onClick={props.onInputChange}
          type="radio"
          value="0"
          name="price"
          id="radio1"
          className={RadioStyle.radioInput}
        />
        <label htmlFor="radio1" className={RadioStyle.radioLabel}>
          Free
        </label>
      </Box>
      <Box className={RadioStyle.radio} mr={4}>
        <input
          checked={parseFloat(props.defaultValue, 10) === 0.99}
          onClick={props.onInputChange}
          type="radio"
          value="0.99"
          name="price"
          id="radio2"
          className={RadioStyle.radioInput}
        />
        <label htmlFor="radio2" className={RadioStyle.radioLabel}>
          $0.99
        </label>
      </Box>
      <Box className={RadioStyle.radio} mr={4}>
        <input
          checked={parseFloat(props.defaultValue, 10) === 1.95}
          onClick={props.onInputChange}
          type="radio"
          value="1.95"
          name="price"
          id="radio3"
          className={RadioStyle.radioInput}
        />
        <label htmlFor="radio3" className={RadioStyle.radioLabel}>
          $1.95
        </label>
      </Box>
      <Box className={RadioStyle.radio} mr={4}>
        <input
          checked={parseFloat(props.defaultValue, 10) === 2.95}
          onClick={props.onInputChange}
          type="radio"
          value="2.95"
          name="price"
          id="radio4"
          className={RadioStyle.radioInput}
        />
        <label htmlFor="radio4" className={RadioStyle.radioLabel}>
          $2.95
        </label>
      </Box>
      <Box className={RadioStyle.radio} mr={4}>
        <input
          checked={parseFloat(props.defaultValue, 10) === 3.95}
          onClick={props.onInputChange}
          type="radio"
          value="3.95"
          name="price"
          id="radio5"
          className={RadioStyle.radioInput}
        />
        <label htmlFor="radio5" className={RadioStyle.radioLabel}>
          $3.95
        </label>
      </Box>
    </React.Fragment>
  );
}
