import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(() => ({
  bgColor: {
   backgroundColor: "#DF8E8E"
  },
}));

const DeleteAlertDialog=(props)=> {
  const classes = useStyles();
  const {open,setOpenDeleteModal,onDelete}= props
  
  const handleClose = () => {
    setOpenDeleteModal(false);
    
  };
const handleDelete=()=>{
  
  setOpenDeleteModal(false)
  onDelete();
  
}
  return (
    <div>
        <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.bgColor}>{"Delete Template"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button  type="button" onClick={()=>handleClose()} color="primary">
            No
          </Button>
          <Button type="button" onClick={()=> handleDelete()} color="danger" >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default DeleteAlertDialog