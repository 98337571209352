import React from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import Templates from './Templates'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#fff',
  },
}))

const Favourites = () => {
  const classes = useStyles()
  
  return (
    <Box className={classes.root}>
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <IconButton
            color="inherit"
            aria-label="Back"
            component={Link}
            to={'/user/cards/new'}
          >
            <ChevronLeftIcon fontSize="large" />
          </IconButton>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h6">Favourites</Typography>
        </Grid>
      </Grid>
      <Templates url="/templates/favourites" />
    </Box>
  )
}

export default Favourites
