import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'

import TopMenu from './TopMenu'
import Confirmation from './Confirmation'

const Home = ({ history, match }) => {
  const handleAuthentication = () => {
    history.push(`/publisher?timestamp=${new Date().toISOString()}`)
  }

  return (
    <Box px={3} overflow="auto" height={1}>
      <TopMenu onAuthenticate={handleAuthentication} />
      <Route path={`${match.path}registration/confirmation/:id`} component={Confirmation} />
    </Box>
  )
}

Home.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

export default Home
