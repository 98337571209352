import React, { useContext, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { fabric } from 'fabric'
import 'fabric-history'
import ReactResizeDetector from 'react-resize-detector'
import WebFont from 'webfontloader'
import VisibilitySensor from 'react-visibility-sensor'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Paper } from '@material-ui/core'

import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'

const useStyles = makeStyles(() => ({
  format_1: {
    position: 'relative',
    paddingTop: '60%',
    backgroundColor: 'unset'
  },
  format_2: { 
    position: 'relative',
    paddingTop: '100%',
    backgroundColor: 'unset'
  },
  format_3: { 
    position: 'relative',
    paddingTop: '125%',
    backgroundColor: 'unset'
  },
  canvas: {
    position: 'absolute',
    top: 0,
    boxShadow: '0 0 16px 0 #d3d4d5',
    // backgroundColor: '#fff',
    // pointerEvents: 'none'
  },
  link: {
    color: '#007aff',
    cursor: 'pointer'
  },
  caption: {
    color: '#9b9b9b'
  }
}))

// fabric.Text.prototype._toSVG = (function(_toSVG) {
//   return function() {
//     console.log('Text SVG: ', this.text, this.fontFamily)
//     if(this.fontFamily === 'Times New Roman') {
//       return _toSVG.call(this)
//     }
//     const makePath = async () => {
//       // const font = await opentype.load(`https://fonts.googleapis.com/css?family=${this.fontFamily}`)
//       console.log('Text data: ', this)
//       const font = await opentype.load(`https://fonts.gstatic.com/s/pacifico/v17/FwZY7-Qmy14u9lezJ96A4sijpFu_.ttf`)
//       const path = font.getPath(this.text, this.translateX, this.translateY, this.fontSize)
//       console.log('Text SVG Path: ', path, path.toSVG(3))
//       return path.toSVG(3)
//     }
//     const pathMarkup =  makePath().then(g => g)
//     console.log('pathMarkup: ', pathMarkup)
//     // return pathMarkup
//     return _toSVG.call(this)
//   }
// })(fabric.Text.prototype._toSVG)

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  let byteArrays = []

  // OPTIMIZE: Convert for loop to functional programming
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: contentType })
}

const dataURIToBlob = dataURI => {
  dataURI = dataURI.replace(/^data:/, '')

  const type = dataURI.match(/image\/[^;]+/)[0]
  return b64toBlob(dataURI.replace(/^[^,]+,/, ''), type)
}

fabric.Object.prototype.set({
  transparentCorners: false,
  borderColor: '#01d9e1',
  borderScaleFactor: 2,
  cornerColor: '#ffffff',
  cornerStyle: 'circle',
  cornerStrokeColor: '#ece9e6'
})

const Side = props => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [side, setSide] = useState({ canvas: null, undo: 0, redo: 0 })
  const [textZoomMargin, setTextZoomMargin] = useState(0)
  const action = props.action
  
  const sideContainerRef = useRef(null)
  const sideBoxRef = useRef(null)
  const canvasRef = useRef(null)

  useEffect(() => {
    if( (side.canvas === null) ) {
      // Initialize Fabric canvas once only, due to it's own internal state
      initCanvas()
    }
  }, [props.side.id, props.side.json])

  useEffect(() => {
    if( side.canvas !== null) {
      applyAction()
    }
  }, [props.side.id, action])

  useEffect(() => {
    if( side.canvas ) {
      side.canvas.setDimensions({ borderRadius: props.cornerRadius })
    }
  }, [props.cornerRadius])

  useEffect(() => {
    if( side.canvas ) {
      side.canvas.copiedStyle = props.copiedStyle
    }
  }, [props.copiedStyle])

  useEffect(() => {
    if( props.active ) {
      sideContainerRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [props.active])

  const initCanvas = () => {
    let fabricCanvas = null
    if( side.canvas === null ) {
      fabricCanvas = new fabric.Canvas(canvasRef.current, {
        preserveObjectStacking: true,
        renderOnAddRemove: false,
        width: sideBoxRef.current.offsetWidth,
        height: sideBoxRef.current.offsetHeight,
        allowTouchScrolling: true
      })
    }
    if(props.side.json !== null) {
      const settings = props.side.settings
      const sideBoxWidth = sideBoxRef.current.offsetWidth
      if ( side.canvas !== null ) {
        // sideBoxRef.current.minWidth = settings.width
        // sideBoxRef.current.minHeight = settings.height
        // handleResize(settings.width)
        side.canvas.setDimensions({
          width: settings.width,
          height: settings.height,
        })
        const ratio = side.canvas.getWidth() / side.canvas.getHeight()
        const scale = sideBoxWidth / side.canvas.getWidth()
        const zoom  = side.canvas.getZoom() * scale
        side.canvas.loadFromJSON( props.side.json )
        // side.canvas.getObjects().forEach(o => {
        //   console.log('side.canvas Fabric object: ', o, o.id)
        //   if(o.id !== 'AlreadySet') {
        //     o.set('id', 'AlreadySet')
        //     o.set('text', 'Temp')
        //   }
        //   console.log('side.canvas Fabric object: ', o, o.id)
        // })
        side.canvas.setDimensions({ width: sideBoxWidth, height: (sideBoxWidth / ratio) })
        side.canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0])
        side.canvas.sideId = props.side.id
        // side.canvas.setDimensions({
        //   width: sideBoxRef.current.offsetWidth,
        //   height: sideBoxRef.current.offsetHeight
        // })
      } else {
        // TODO: Improve loading with correct dimensions.
        // Ref: https://github.com/fabricjs/fabric.js/issues/741
        fabricCanvas.setDimensions({
          width: settings.width,
          height: settings.height,
        })
        const ratio = fabricCanvas.getWidth() / fabricCanvas.getHeight()
        const scale = sideBoxWidth / fabricCanvas.getWidth()
        const zoom  = fabricCanvas.getZoom() * scale
        let fontFamilies = []
        const sideJSON = JSON.parse(props.side.json)
        if( sideJSON.objects !== undefined ) {
          const textObjects = sideJSON.objects.filter(o => o.type === 'textbox' && o.fontFamily !== 'Times New Roman')
          fontFamilies = textObjects.map(o => o.fontFamily.replace(/\s/g, '+'))
        }
        const loadFabricCanvas = () => {
          fabricCanvas.loadFromJSON( props.side.json, fabricCanvas.renderAll.bind(fabricCanvas), (o, object) => {
            // console.log('Revivier: ', o.id, object.id)
            if(object.id !== undefined && object.id.startsWith('DataField-')) {
              const dataField = props.dataFields.find(df => `DataField-${df.id}` === object.id)
              if( dataField !== undefined ) {
                // console.log('dataField: ', object.id, dataField)
                object.set('id', undefined)
                object.set('text', dataField.fieldValue)
              }
            }
          })
        }
        if( fontFamilies .length ) {
          WebFont.load({
            google: { families: fontFamilies },
            active: () => {
              loadFabricCanvas()
            }
          })
        } else {
          loadFabricCanvas()
        }
        fabricCanvas.setDimensions({ width: sideBoxWidth, height: (sideBoxWidth / ratio) })
        fabricCanvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0])
        // TODO: Fix Undo/Redo history logging
        // fabricCanvas.offHistory()
        // if( settings !== null ) {
        //   fabricCanvas.setDimensions({
        //     width: settings.width,
        //     height: settings.height,
        //   })
        // }
        
        // fabricCanvas.loadFromJSON( props.side.json, fabricCanvas.renderAll.bind(fabricCanvas) )
        // attachHandlers(fabricCanvas)
        // fabricCanvas.clearHistory()
        // fabricCanvas.historyUndo = JSON.parse( props.side.historyUndo )
        // fabricCanvas.historyRedo = JSON.parse( props.side.historyRedo )
        // fabricCanvas.historyNextState = props.side.historyNextState
        // const ratio = fabricCanvas.getWidth() / fabricCanvas.getHeight()
        // const scale = sideBoxRef.current.offsetWidth / fabricCanvas.getWidth()
        // const zoom  = fabricCanvas.getZoom() * scale
        // fabricCanvas.setDimensions({ width: sideBoxRef.current.offsetWidth, height: (sideBoxRef.current.offsetWidth / ratio) })
        // fabricCanvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0])
      }
    }
    if( side.canvas === null ) {
      attachHandlers(fabricCanvas)
      side.canvas = fabricCanvas
      side.canvas.sideId = props.side.id
      setSide({
        ...side,
        undo: side.canvas.historyUndo.length,
        redo: side.canvas.historyRedo.length,
      })
      // side.canvas.fire('object:modified')
      saveSide( side.canvas )
    }
  }

  const attachHandlers = fabricCanvas => {
    fabricCanvas.on({
      'selection:created': handleSelectionCreated,
      'selection:updated': handleSelectionCreated,
      'selection:cleared': handleSelectionCleared,
      'object:added': handleCanvasUpdate,
      'object:removed': handleCanvasUpdate,
      'object:modified': handleCanvasUpdate,
      'mouse:down': handleMouseDown,
      'touch:longpress': handleDoubleClick,
      'text:changed': handleCanvasUpdate,
      'text:editing:entered': handleTextEditing,
      // 'object:scaling': handleObjectScaling,
    })
    fabric.util.addListener(fabricCanvas.upperCanvasEl, 'tap', function(e) {
      if(fabricCanvas.findTarget(e)) {
        console.log(fabricCanvas.findTarget(e))
      }
    })
  
    
    document.addEventListener('focusout', () => {
      setTextZoomMargin(0)
    })
    sideBoxRef.current.tabIndex = 1000  // To focus canvas container for catching keydown event
    sideBoxRef.current.addEventListener('keydown', e => {
      if (e.key === 'Backspace' || e.key === 'Delete') {
        const selection = fabricCanvas.getActiveObject()
        if (selection) {
          if (selection.type === 'activeSelection') { // if group selected
            selection.forEachObject(element => fabricCanvas.remove(element))
          } else{
            fabricCanvas.remove(selection)
          }
          fabricCanvas.discardActiveObject()
          fabricCanvas.requestRenderAll()
        }
      }
    })
  }

  // const handleObjectScaling = event => {
  //   if( event.target.type === 'group' && event.target.item(0).type === 'image') {
  //     const group = event.target
  //     const img = group.item(0)
  //     if(event.transform.currentAction !== 'scaleEqually') {
  //       const scaleX = (img.width * group.zoomX) / group.getScaledWidth()
  //       const scaleY = (img.height * group.zoomY) / group.getScaledHeight()
  //       // console.log('Scales: ', img.getScaledWidth(), group.getScaledWidth(), scaleX, scaleY)
  //       // console.log('scaleX, scaleY: ', scaleX, scaleY)
  //       // const invTrans = fabric.util.invertTransform(event.target.canvas.viewportTransform)
  //       // console.log('viewportTransform, invViewportTransform: ', event.target.canvas.viewportTransform, invTrans)
  //       if(event.transform.action === 'scaleX') {
  //         img.set({
  //           scaleX,
  //           // scaleY
  //         })
  //       } else {
  //         img.set({
  //           scaleY,
  //           // scaleY
  //         })
  //       }
  //       console.log('img: ', group.zoomX)
  //       // img.setCoords()
  //       // group.setCoords()
  //     }
  //     // this._resetCurrentTransform(event, img)
  //     // img.setCoords()
  //     // group.setCoords()
  //   }
  // }

  const applyAction = () => {
    const activeObj = side.canvas.getActiveObject()
    if( Object.keys(action).length !== 0 ) {
      const zoomFactor = 1 / side.canvas.getZoom()
      switch(action.type) {
        case 'addObject':
          if(action.property && action.property === 'image')
            action.value.scaleToWidth((side.canvas.getWidth()/2) * zoomFactor)
          else {
            action.value.scaleX = zoomFactor
            action.value.scaleY = zoomFactor
          }
          side.canvas.viewportCenterObject(action.value)
          side.canvas.add(action.value)
            .setActiveObject(action.value)
          break
        case 'addTextStyling':
          fabric.util.enlivenObjects(JSON.parse(action.value).objects, objects => {
            const group = new fabric.Group(objects, {
              originX: 'center',
              originY: 'center',
              subTargetCheck: true,
              objectCaching: false
            })
            group.scaleToWidth((side.canvas.getWidth()/2) * zoomFactor)
            side.canvas.viewportCenterObject(group)
            // group.center().setCoords()
            side.canvas.add(group)
              .setActiveObject(group)
          })
          break
        case 'group':
          activeObj.toGroup()
          activeObj.type = 'group'
          props.onToolbarChange( getObjectData(activeObj) )
          break
        case 'ungroup':
          const objectsInGroup = activeObj.getObjects()
          activeObj.destroy()
          side.canvas.remove(activeObj)
          objectsInGroup.forEach(obj => side.canvas.add(obj))
          break
        case 'text':
          if( activeObj ) {
            if(action.property === 'fill') {
              activeObj.set({
                [action.property]: action.value.hex
              })
            } else {
              activeObj.set({
                styles: {},
                [action.property]: action.value
              })
            }
            props.onToolbarChange( getObjectData(activeObj) )
          }
          break
        case 'background':
          if(action.property === 'image') {
            const canvasAspect = side.canvas.getWidth() / side.canvas.getHeight()
            const imgAspect = action.value.width / action.value.height
            let left, top, scaleFactor
            if (canvasAspect >= imgAspect) {
              scaleFactor = side.canvas.getWidth() / action.value.width
              left = 0
              top = -((action.value.height * scaleFactor) - side.canvas.getHeight()) / 2
            } else {
              scaleFactor = side.canvas.getHeight() / action.value.height
              top = 0
              left = -((action.value.width * scaleFactor) - side.canvas.getWidth()) / 2
            }
            scaleFactor = scaleFactor / side.canvas.getZoom()
            side.canvas.setBackgroundImage(action.value, side.canvas.renderAll.bind(side.canvas), {
              top: top,
              left: left,
              originX: 'left',
              originY: 'top',
              scaleX: scaleFactor,
              scaleY: scaleFactor
            })
            side.canvas.setBackgroundColor(null)
          } else if(action.property === 'color') {
            let sideColors
            if(props.side.colors === null || props.side.colors === undefined) {
              sideColors = []
            } else {
              sideColors = [...props.side.colors.filter(c => c.type === undefined)]
            }
            if(side.canvas.backgroundColor !== '') {
              sideColors = sideColors.filter(c => c.hex !== side.canvas.backgroundColor)
            }
            side.canvas.backgroundImage = false
            side.canvas.setBackgroundColor(action.value.hex)
            sideColors.push(action.value)
            side.canvas.colors = sideColors
          }
          side.canvas._historySaveAction()  // For undo, redo history, fabric-history plugin
          break
        case 'alignment':
          if( activeObj ) {
            const activeObjectBoundingRect = activeObj.getBoundingRect()
            const viewportTransform = side.canvas.viewportTransform
            switch (action.value) {
              case 'forward':
                side.canvas.bringToFront(activeObj)
                break
              case 'backward':
                side.canvas.sendBackwards(activeObj)
                break
              case 'top':
                activeObj.set({
                  originY: 'top',
                  top: 0
                })
                break
              case 'middle':
                activeObj.viewportCenterV()
                break
              case 'bottom':
                activeObj.set({
                  top: (side.canvas.getHeight()/viewportTransform[3] - (activeObjectBoundingRect.height/viewportTransform[3]))
                })
                break
              case 'left':
                activeObj.set({
                  originX: 'left',
                  left: 0
                })
                break
              case 'center':
                activeObj.viewportCenterH()
                break
              case 'right':
                activeObj.set({
                  left: (side.canvas.getWidth()/viewportTransform[0] - (activeObjectBoundingRect.width/viewportTransform[0]))
                })
                break
            }
          }
          props.onToolbarChange( getObjectData(activeObj) )
          break
        case 'flip':
          activeObj.set({
            [action.property]: action.value
          })
          props.onToolbarChange( getObjectData(activeObj) )
          break
        case 'duplicate':
          activeObj.clone(clonedObj => {
            side.canvas.discardActiveObject()
            clonedObj.set({
              left: clonedObj.left + 10,
              top: clonedObj.top + 10,
              evented: true,
            })
            if (clonedObj.type === 'activeSelection') {
              // active selection needs a reference to the canvas.
              clonedObj.canvas = side.canvas
              clonedObj.forEachObject(obj => side.canvas.add(obj))
              // this should solve the unselectability
              clonedObj.setCoords()
            } else {
              side.canvas.add(clonedObj)
            }
            side.canvas.setActiveObject(clonedObj)
            side.canvas.renderAll()      
          })
          break
        case 'remove':
          if (activeObj) {
            if (activeObj.type === 'activeSelection') { // if group selected
              activeObj.forEachObject(element => removeObject(element))
            } else{
              removeObject(activeObj)
            }
            side.canvas.discardActiveObject()
          }
          break
      }
      side.canvas.renderAll()
      setSide({
        ...side,
        undo: side.canvas.historyUndo.length,
        redo: side.canvas.historyRedo.length,
      })
      saveSide( side.canvas )
    }
  }

  const removeObject = obj => {
    side.canvas.remove(obj)
  }

  const handleSelectionCreated = e => {
    if(e.target.type === 'textbox' && Object.keys(e.target.canvas.copiedStyle).length !== 0) {
      e.target.set(e.target.canvas.copiedStyle)
      e.target.canvas.copiedStyle = {}
      saveSide( e.target.canvas )
      props.onCopiedStyleChange({})
    }
    props.onToolbarChange( getObjectData(e.target) )
  }

  const handleMouseDown = options => {
    if(options.target && options.target.type === 'textbox' && options.target.isEditing) {
      setTextZoomMargin(-(options.target.top * options.target.canvas.viewportTransform[3] - options.target.height))
    }
  }

  const handleDoubleClick = options => {
    console.log('handleDoubleClick: ', options)
    if(options.target !== null && options.target.type === 'group') {
      const pointer = options.target.canvas.getPointer(options.e, true)
      const normalizedPointer = options.target.canvas._normalizePointer(options.target, pointer)
      options.target.forEachObject(object => {
        if(object.type === 'textbox') {
          if( options.target.canvas._checkTarget(normalizedPointer, object) ) {
            object.editable = true
            object.hasControls = true
            options.target.canvas.setActiveObject(object)
            object.enterEditing(options.e)
          }
        }
      })
      options.target.objectCaching = false
      options.target.canvas.requestRenderAll()
    }
  }

  const handleTextEditing = options => {
    options.target.objectCaching = false
    options.target.selectAll()
    const objectData = getObjectData(options.target)
    objectData.type = 'textEdit'
    setTextZoomMargin(-(options.target.top * options.target.canvas.viewportTransform[3] - options.target.height))

    props.onToolbarChange( objectData )
  }

  const handleSelectionCleared = () => {
    setTextZoomMargin(0)
    props.onCopiedStyleChange({})
    props.onToolbarChange({ type: 'default' })
  }

  const handleCanvasUpdate = e => {
    const canvas = e.target.canvas
    setSide({
      ...side,
      undo: canvas.historyUndo.length,
      redo: canvas.historyRedo.length
    })
    saveSide( canvas )
    return true // successfully return control even if remote call saveSide doesn't work
  }

  const saveSide = canvas => {
    const colors = canvas.colors !== undefined ? canvas.colors : []
    if( Number.isInteger(canvas.sideId) ) {
      const formData = new FormData()
      const jpegBlob = dataURIToBlob( canvas.toDataURL({ format: 'jpeg', enableRetinaScaling: true }) )
      // // const jpegFile = new File([jpegBlob], `${canvas.sideId}.jpeg`, { type: 'image/jpeg' })
      formData.append(
        'file',
        jpegBlob,
        `${canvas.sideId}.jpeg`
      )
      let canvasSVG = canvas.toSVG({ suppressPreamble: true })
      if( canvas.backgroundColor !== '' ) {
        const vpt = canvas.viewportTransform
        const viewBoxWidth = fabric.util.toFixed(canvas.width / vpt[0], fabric.Object.NUM_FRACTION_DIGITS)
        const viewBoxHeight = fabric.util.toFixed(canvas.height / vpt[3], fabric.Object.NUM_FRACTION_DIGITS)
        canvasSVG = canvasSVG.replace(
          `<rect x="0" y="0" width="100%" height="100%" fill="${canvas.backgroundColor}"></rect>`,
          `<rect x="0" y="0" width="${viewBoxWidth}" height="${viewBoxHeight}" fill="${canvas.backgroundColor}"></rect>`,
        )
      }
      let fontFamilies = []
      canvas.getObjects().forEach(o => {
        if( o.type === 'textbox' && o.fontFamily !== 'Times New Roman') {
          fontFamilies.push(o.fontFamily.replace(/\s/g, '+'))
        }
      })
      if( fontFamilies.length > 0 ) {
        canvasSVG = canvasSVG.replace(
          '<defs>',
          `<defs><style type="text/css">@import url('https://fonts.googleapis.com/css?family=${fontFamilies.join('|')}');</style>`
        )
      }
      HttpClient.post(`/upload?storageableType=CardSide&storageableId=${canvas.sideId}`, formData, { Authorization: `Bearer ${authData.authToken}` })
        .then(res => {
          HttpClient.put(
            `/cards/${props.cardId}/sides/${canvas.sideId}`,
            {
              position: props.side.position,
              svg: canvasSVG,
              draftPreviewImagePath: res[0].path,
              json: JSON.stringify(canvas.toDatalessJSON(['id'])),
              historyUndo: JSON.stringify(canvas.historyUndo),
              historyRedo: JSON.stringify(canvas.historyRedo),
              historyNextState: canvas.historyNextState,
              colors,
              // settings: { width: canvas.width, height: canvas.height },
            },
            { Authorization: `Bearer ${authData.authToken}` }
          )
            .then(() => {
              // props.onSideUpdate({ ...props.side, ...res })
            })
            .catch(error => console.log('Error side update: ', error.response.status))
        })
    }
  }

  const getObjectData = selectedObj => {
    switch ( selectedObj.type ) {
      case 'text':
      case 'textbox':
        return {
          type: 'text',
          fontFamily: selectedObj.get('fontFamily'),
          fontSize: selectedObj.get('fontSize'),
          lineHeight: selectedObj.get('lineHeight'),
          charSpacing: selectedObj.get('charSpacing'),
          fontWeight: selectedObj.get('fontWeight'),
          textColor: selectedObj.get('fill'),
          fontStyle: selectedObj.get('fontStyle'),
          underline: selectedObj.get('underline'),
          textAlign: selectedObj.get('textAlign'),
          scaleX: selectedObj.get('scaleX'),
          scaleY: selectedObj.get('scaleY'),
          top: selectedObj.get('top'),
          bottom: selectedObj.get('bottom'),
        }
      case 'image':
        return {
          type: 'image',
          flipX: selectedObj.get('flipX'),
          flipY: selectedObj.get('flipY'),
        }
      case 'group':
        return {
          type: 'group',
          flipX: selectedObj.get('flipX'),
          flipY: selectedObj.get('flipY'),
        }
      case 'activeSelection':
        return {
          type: 'activeSelection',
          flipX: selectedObj.get('flipX'),
          flipY: selectedObj.get('flipY'),
        }
      default:
        return { type: 'default' }
    }  
  }

  // const undo = () => {
  //   side.canvas.undo()
  //   setSide({
  //     ...side,
  //     undo: side.canvas.historyUndo.length,
  //     redo: side.canvas.historyRedo.length
  //   })
  // }

  // const redo = () => {
  //   side.canvas.redo()
  //   setSide({
  //     ...side,
  //     undo: side.canvas.historyUndo.length,
  //     redo: side.canvas.historyRedo.length
  //   })
  // }

  // const handleSideActivation = () => {
  //   // setAction({})
  // }

  const handleResize = width => {
    const ratio = side.canvas.getWidth() / side.canvas.getHeight()
    const scale = width / side.canvas.getWidth()
    const zoom  = side.canvas.getZoom() * scale
    side.canvas.setDimensions({ width, height: (width / ratio) })
    side.canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0])
  }

  return (
    <VisibilitySensor onChange={isVisible => {
      if( isVisible ) {
        props.onActivate(props.side.id)
      }
    }}>
      <Box
        ref={sideContainerRef}
        p={2}
        py="2.25%"
        style={{ pointerEvents: props.navigation === 1 ? 'none' : 'auto' }}
      >
        <Box
          margin="0 auto"
          maxWidth="100%"
          style={{
            marginTop: `${textZoomMargin}px`
          }}
        >
          <Paper
            square
            elevation={0}
            className={classes[`format_${props.formatType}`]}
          >
            {props.navigation === 1 && props.active ?
              <Box
                position="absolute"
                top={0}
                bottom={0}
                width={1}
                zIndex={1}
                bgcolor="rgb(255 255 255 / 0.4)"
              />
              : null
            }
            <Box
              id={`side-${props.side.id}`}
              ref={sideBoxRef}
              position="absolute"
              top={0}
              bottom={0}
              width={1}
            >
              <ReactResizeDetector handleWidth skipOnMount onResize={handleResize}>
                <canvas
                  id={`canvas-${props.side.id}`}
                  ref={canvasRef}
                  className={classes.canvas}
                  style={{ borderRadius: `${props.cornerRadius}px` }}
                  tabIndex="1"
                />
              </ReactResizeDetector>
            </Box>
          </Paper>
        </Box>
      </Box>
    </VisibilitySensor>
  )
}

Side.propTypes = {
  navigation: PropTypes.number.isRequired,
  cardId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  formatType: PropTypes.number.isRequired,
  side: PropTypes.object.isRequired,
  dataFields: PropTypes.array.isRequired,
  active: PropTypes.bool.isRequired,
  onActivate: PropTypes.func.isRequired,
  action: PropTypes.object.isRequired,
  copiedStyle: PropTypes.object.isRequired,
  onCopiedStyleChange: PropTypes.func.isRequired,
  removeAble: PropTypes.bool.isRequired,
  cornerRadius: PropTypes.number.isRequired,
  onToolbarChange: PropTypes.func.isRequired,
  // onAdd: PropTypes.func.isRequired,
  // onRemove: PropTypes.func.isRequired,
}

export default Side
