import React, { useState } from "react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { first } from 'lodash/fp';
import {
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "./imageSlider.css";
const useStyles = makeStyles((theme) => ({
  contentLeftSection: {
    display: 'flex !important',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginRight: '10px',
    marginBottom: '10px',
    width: '500px',
    "& svg": {
      width: "165px",
      height: "100%",
    },
  },
  image: {
    objectFit: 'cover',
    marginRight: '20px',
  },
}));
const ImageSlider = (props) => {
  const { currentTemplate } = props;
  const classes = useStyles();
  const [current, setCurrent] = useState(0);

  const length = first(currentTemplate.formats).sides?.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(first(currentTemplate.formats).sides) || length <= 0) {
    return null;
  }

  const currentSide = current+1;

  return (
    <section className="slider">
      {first(currentTemplate.formats).sides?.map((slide, index) => {
        return (
          <div
            className={index === current ? "slide active" : "slide"}
            key={index}
          >
            {index === current && (
              <Box className={classes.contentLeftSection}>
                <Box
                  className={classes.image}
                  dangerouslySetInnerHTML={{
                    __html: currentTemplate.formats[0].sides && currentTemplate.formats[0].sides[index] && currentTemplate.formats[0].sides[index].svg,
                  }}
                ></Box>
                <Box
                  className={classes.image}
                  dangerouslySetInnerHTML={{
                    __html: currentTemplate.formats[1].sides && currentTemplate.formats[1].sides[index] && currentTemplate.formats[1].sides[index].svg,
                  }}
                ></Box>
                <Box
                  className={classes.image}
                  dangerouslySetInnerHTML={{
                    __html: currentTemplate.formats[2].sides && currentTemplate.formats[2].sides[index] && currentTemplate.formats[2].sides[index].svg,
                  }}
                ></Box>
              </Box>
            )}
          </div>
        );
      })}
      <div className="wrapperclass">
        <div className="bottomIconBar">
          { currentSide !== 1 && <ChevronLeftIcon className="left-arrow" onClick={prevSlide} />}
          <p>Slide {currentSide}/{length}</p>
          { currentSide !== length && <ChevronRightIcon className="right-arrow" onClick={nextSlide} />}
        </div>
      </div>
    </section>
  );
};

export default ImageSlider;
