import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'

import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import { getRandomInt } from '../../../../utils/math'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
  },
  cards: {
    overflowX: 'auto',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  },
  card: {
    flex: 'none',
    width: '27%',
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
  format_1: {
    position: 'relative',
    paddingBottom: '60%',
    borderRadius: '4px',
    border: '1px solid #eee',
  },
  format_2: {
    flex: '0 1 auto',
    position: 'relative',
    paddingBottom: '100%',
    borderRadius: '4px',
    border: '1px solid #eee',
  },
  format_3: {
    flex: '0 1 auto',
    position: 'relative',
    paddingBottom: '125%',
    borderRadius: '4px',
    border: '1px solid #eee',
  },
  svg: {
    '& svg': {
      maxWidth: '100%',
      maxHeight: '100%'
    },
  },
}))

const Style = ({ style }) => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [templates, setTemplates] = useState(true)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    HttpClient.get(
      `/templates?categories=${style.id}`,
      { Authorization: `Bearer ${authData.authToken}` }
    )
      .then(res => {
        setTemplates(res)
        setLoading(false)
      })
      .catch(error => {
        console.log('Error getting templates by style: ', error)
        setTemplates([])
        setLoading(false)
      })
  }, [authData.authToken, style.id])

  return (
    templates.length > 0 ?
      <Box marginBottom={2} paddingBottom={1} className={classes.root}>
        <Box px={2}>
          <Grid container>
            <Grid item xs={8}>
              <Typography gutterBottom variant="h6">{style.title}</Typography>
            </Grid>
            <Grid container item xs={4} justify="flex-end" alignItems="center">
              <Link
                to={{
                  pathname: `/user/cards/new/category/${style.id}`,
                  state: {
                    from: `/user/cards/new/${style.type === 1 ? 'business' : 'styles'}`
                  }
                }}
                className={classes.link}
              >
                see all
              </Link>
            </Grid>
          </Grid>    
        </Box> 
        <Box display="flex" width={1} px={1} className={classes.cards}>
          {loading ? 
            <React.Fragment>
              {[...Array(4).keys()].map(i =>
                <Box key={i} className={classes.card}>
                  <Box className={classes[`format_${getRandomInt(3)+1}`]}>
                    <Box
                      position="absolute"
                      top="0"
                      left="0"
                      bottom="0"
                      right="0"
                    >
                      <Skeleton variant="rect" width="100%" height="100%" />
                    </Box>
                  </Box>
                </Box>
              )}
            </React.Fragment>
            :
            <React.Fragment>
              {templates.map(template =>
                <Box key={template.id} className={classes.card}>
                  <Box
                    component={Link}
                    to={`/user/cards/new/template/${template.id}`}
                    className={classes.format_3}
                    display="block"
                  >
                    <Box
                      position="absolute"
                      top={0}
                      bottom={0}
                      width={1}
                      dangerouslySetInnerHTML={{
                        __html: (template.square ? template.square.previewImage : '')
                      }}
                      className={classes.svg}
                    />
                  </Box>
                </Box>
              )}
            </React.Fragment>
          }
        </Box>
      </Box>
      :
      null
  )
}

Style.propTypes = {
  style: PropTypes.object.isRequired
}

export default Style
