import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Download = props => {
  return (
    <SvgIcon {...props}>
      <g transform="translate(2.000000, 0.000000)" fill="#FFFFFF" fillRule="nonzero">
        <path d="M4.31134244,14.0467743 L9.41303319,18.7213008 C9.6626866,18.9500426 10.0515991,18.9500426 10.3012525,18.7213008 L15.4029433,14.0467743 C15.6637375,13.8079155 15.6763973,13.4081724 15.4313015,13.1540148 C15.1859524,12.8998572 14.7757713,12.8875194 14.5149772,13.1263782 L10.5053302,16.8000664 L10.5053302,0.631692763 C10.5053302,0.282781182 10.2151651,0 9.85714282,0 C9.49912059,0 9.2089554,0.282781182 9.2089554,0.631692763 L9.2089554,16.8000664 L5.19930859,13.1266249 C4.9385144,12.8875194 4.52833329,12.8998572 4.28298424,13.1540148 C4.03788837,13.4084192 4.05054825,13.8081622 4.31134244,14.0470211 L4.31134244,14.0467743 Z" />
        <path d="M16.4834958,4.10714286 L13.2070204,4.10714286 C12.8501191,4.10714286 12.5608625,4.39507089 12.5608625,4.75033282 C12.5608625,5.1055948 12.8501191,5.39352278 13.2070204,5.39352278 L16.4834958,5.39352278 C17.5536949,5.39477899 18.4207077,6.25780929 18.4219697,7.32309266 C18.4219697,7.53041873 18.4219697,7.91082825 18.4219697,8.46432121 C18.4219697,11.2797032 18.4219697,15.0529462 18.4219697,19.7840502 C18.4207077,20.8493336 17.5536949,21.7123639 16.4834958,21.7136201 L3.23078995,21.7136201 C2.16059078,21.7123639 1.29357799,20.8493336 1.29231598,19.7840502 C1.29231598,15.947481 1.29231598,13.0700541 1.29231598,11.1517695 C1.29231598,10.3009524 1.29231598,9.02472682 1.29231598,7.32309266 C1.29357799,6.25780929 2.16059078,5.39477899 3.23078995,5.39352278 L6.50751772,5.39352278 C6.86416663,5.39352278 7.15367571,5.1055948 7.15367571,4.75033282 C7.15367571,4.39507089 6.86416663,4.10714286 6.50751772,4.10714286 L3.23078995,4.10714286 C1.44729297,4.10915283 0.00201924372,5.5477879 0,7.32309266 L0,19.7840502 C0.00201924372,21.559355 1.44729297,22.99799 3.23078995,23 L17.1632987,23 C18.9467958,22.99799 19.7122665,21.559355 19.7142857,19.7840502 L19.7142857,7.32309266 C19.7122665,5.5477879 18.2669928,4.10915283 16.4834958,4.10714286 Z" />
      </g>
    </SvgIcon>
  )
}

export default Download
