import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import HttpClient from "../../services/HttpClient";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, TextField, Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
// import LogoIcon from '../../icons/LogoIcon'
import MaterialUiPhoneNumber from "material-ui-phone-number";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(() => ({
  root: {
    minHeight: "80vh",
  },
  // logo: {
  //   fontSize: '4.4rem',
  //   marginTop: '16%',
  //   marginBottom: '3%',
  // },
  actions: {
    marginTop: "5%",
    marginBottom: "20%",
    justifyContent: "flex-end",
  },
  live: {
    color: "#007AFF",
    fontWeight: "bold",
  },
  title: {
    color: "#0E1F4A",
    font: "lato",
    fontSize: "45px",
    lineHeight: 1.2,
    fontWeight: "bold",
    marginBottom: '-16px',
    marginTop:'30px'
  },
  contact: {
    color: "#29406B",
  },
}));

const initialDesigner = {
  nickName: "",
  email: "",
  mobileNumber: "",
  password: "",
  password_confirmation: "",
  designer: true,
};

const initialErrorMessages = {
  nickName: "",
  email: "",
  mobileNumber: "",
  password: "",
  password_confirmation: "",
};

const Registration = (props) => {
  const classes = useStyles();
  const [designer, setDesigner] = useState(initialDesigner);
  const [errors, setErrors] = useState(initialErrorMessages);
  const [generalError, setGeneralError] = useState(null);
  const [registered, setRegistered] = useState(false);

  useEffect(() => {
    setDesigner(initialDesigner);
    setRegistered(false);
    setGeneralError(null);
  }, [props.open]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const _errors = { ...errors, ...initialErrorMessages };
    let isValid = true;
    if (designer.nickName === "") {
      isValid = false;
      _errors.nickName = "Required";
    }
    if (designer.email === "") {
      isValid = false;
      _errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(designer.email)
    ) {
      isValid = false;
      _errors.email = "Invalid E-mail";
    }
    if (designer.mobileNumber === "") {
      isValid = false;
      _errors.mobileNumber = "Required";
    }
    if (designer.password === "") {
      isValid = false;
      _errors.password = "Password can't be blank";
    } else if (designer.password.length < 8) {
      isValid = false;
      _errors.password = "Password must have minimum 8 characters";
    }
    if (designer.password !== designer.password_confirmation) {
      isValid = false;
      _errors.password_confirmation = "Passwords doesn't match";
    }

    if (isValid) {
      const { password_confirmation, ...apiData } = designer;
      apiData.displayName = apiData.nickName;
      apiData.mobileNumber = apiData.mobileNumber.replace(/[^0-9\+]/g, "");
      HttpClient.post("/signup", apiData)
        .then(() => setRegistered(true))
        .catch((error) => {
          error.response.text().then((response) => {
            setGeneralError(JSON.parse(response).error.message);
          });
        });
      // props.onClose()
    } else {
      setErrors(_errors);
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="registration-dialog-title"
      classes={{ paper: classes.root }}
    >
      <Grid container>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <DialogTitle id="registration-dialog-title">
            <Typography className={classes.title}>
              <span className={classes.live}> Live</span>
              <span className={classes.contact}>Contact</span>
            </Typography>
          </DialogTitle>
          {!registered ? (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Typography
                  variant="h6"
                  component="h2"
                  color="textPrimary"
                  gutterBottom
                >
                  Register:
                </Typography>
                <TextField
                  id="username"
                  label="Full Name"
                  value={designer.nickName}
                  onChange={(e) =>
                    setDesigner({
                      ...designer,
                      nickName: e.currentTarget.value,
                    })
                  }
                  margin="normal"
                  fullWidth
                  autoFocus
                  required
                  error={errors.nickName !== ""}
                  helperText={errors.nickName}
                />
                <TextField
                  id="email"
                  label="E-mail"
                  type="email"
                  autoComplete="username"
                  value={designer.email}
                  onChange={(e) =>
                    setDesigner({ ...designer, email: e.currentTarget.value })
                  }
                  margin="normal"
                  fullWidth
                  required
                  error={errors.email !== ""}
                  helperText={errors.email}
                />
                <MaterialUiPhoneNumber
                  id="phone"
                  label="Mobile phone number"
                  type="phone"
                  value={designer.mobileNumber}
                  onChange={(value) =>
                    setDesigner({ ...designer, mobileNumber: value })
                  }
                  margin="normal"
                  fullWidth
                  required
                  error={errors.mobileNumber !== ""}
                  helperText={errors.mobileNumber}
                />
                <TextField
                  id="password"
                  label="Password"
                  type="password"
                  autoComplete="new-password"
                  value={designer.password}
                  onChange={(e) =>
                    setDesigner({
                      ...designer,
                      password: e.currentTarget.value,
                    })
                  }
                  margin="normal"
                  fullWidth
                  required
                  error={errors.password !== ""}
                  helperText={errors.password}
                />
                <TextField
                  id="password-confirmation"
                  label="Confirm password"
                  type="password"
                  autoComplete="new-password"
                  value={designer.password_confirmation}
                  onChange={(e) =>
                    setDesigner({
                      ...designer,
                      password_confirmation: e.currentTarget.value,
                    })
                  }
                  margin="normal"
                  fullWidth
                  required
                  error={errors.password_confirmation !== ""}
                  helperText={errors.password_confirmation}
                />
              </DialogContent>
              {generalError ? (
                <Box paddingTop={2}>
                  <Alert severity="error">{generalError}</Alert>
                </Box>
              ) : null}
              <DialogActions className={classes.actions}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  Sign Up
                </Button>
              </DialogActions>
            </form>
          ) : (
            <React.Fragment>
              <DialogContent>
                <Typography
                  variant="h6"
                  component="h2"
                  color="textPrimary"
                  gutterBottom
                >
                  Register:
                </Typography>
                <Typography
                  variant="body1"
                  component="h4"
                  color="textPrimary"
                  gutterBottom
                >
                  Thank you for Signing Up.
                </Typography>
                <DialogContentText>
                  We have sent a confirmation email to {designer.email}. Please
                  click the link in the email to confirm your account.
                </DialogContentText>
              </DialogContent>
              <DialogActions className={classes.actions}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={props.onClose}
                >
                  Close
                </Button>
              </DialogActions>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

Registration.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Registration;
