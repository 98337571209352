import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { TextField, InputAdornment } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import SearchIcon from '@material-ui/icons/Search'

import HeaderBgTop from '../../../../images/template-selection-top.png'
import HeaderBgBottom from '../../../../images/template-selection-bottom.png'
import HomeIcon from '../../icons/Home'
import StarIcon from '../../icons/Star'
// import LogoIcon from '../../../../icons/LogoIcon'
import Home from './Home'
import Styles from './Styles'
import Business from './Business'
import SubCategory from './SubCategory'
import Favourites from './Favourites'
import Template from './Template'
import SearchResults from './SearchResults'

const useStyles = makeStyles(() => ({
  root: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  },
  appBarTop: {
    paddingBottom: '34.7%',
    // background: 'transparent',
    backgroundImage: `url(${HeaderBgTop})`,
    backgroundSize: 'contain'
  },
  appBarBottom: {
    paddingBottom: '26.1%',
    // background: 'transparent',
    backgroundImage: `url(${HeaderBgBottom})`,
    backgroundSize: 'contain'
  },
  heading: {
    width: '70%',
    color: '#054C99',
    fontSize: '1.563rem',
    fontWeight: 'bold'
  },
  searchBar: {
    backgroundColor: '#fff',
    borderRadius: '0.3em'
  },
  searchBarInput: {
    color: '#989c9c',
    paddingLeft: '10px',
    borderRadius: '0.3em',
    '& input': {
      paddingTop: '9px',
      paddingBottom: '9px'
    },
    '& fieldset': {
      borderColor: '#ededef',
    },
  },
  navigation: {
    height: 'auto',
  },
  navigationActionWrapper: {
    '& svg': {
      fontSize: '2.5em',
    },
  },
  navigationActionLabel: {
    paddingTop: '0.7em',
  },
}))

const New = ({ match, location }) => {
  const classes = useStyles()
  const [search, setSearch] = useState('')

  useEffect(() => {
    if( /\/template\/\d+$/.test(location.pathname) ) {
      setSearch('')
    }
  }, [location.pathname])
  
  const handleClose = () => {
    // const userAgent = window.navigator.userAgent.toLowerCase()
    // const ios = /iphone|ipod|ipad/.test( userAgent )
    // setIndustry({})
    if(window.Print !== undefined)
      window.Print.postMessage('close')
  }

  return (
    <Box height={1} paddingBottom={10} className={classes.root}>
      <Box
        position="relative"
        className={classes.appBarTop}
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          bottom="0"
          right="0"
          padding={1.8}
        >
          <Box display="flex" height="100%" flexDirection="column">
            <Box
              display="flex"
              flex="1 0 auto"
              alignItems="flex-end"
            >
              <Typography variant="h5" className={classes.heading}>
                Create Something Wonderful
              </Typography>
            </Box>
          </Box>
        </Box> 
      </Box>
      <Box
        position="sticky"
        top={0}
        zIndex="2"
        className={classes.appBarBottom}
      >
        <Box
          display="flex"
          alignItems="flex-end"
          position="absolute"
          top="0"
          left="0"
          bottom="0"
          right="0"
          padding={1.8}
        >
          <Box width={1}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={search}
              placeholder="Search for industry, style, theme..."
              className={classes.searchBar}
              onChange={e => setSearch(e.currentTarget.value)}
              InputProps={{
                className: classes.searchBarInput,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box> 
      </Box>
      {search !== '' ?
        <SearchResults url={`/templates?name=${search}`} onClose={() => setSearch('')} />
        : null
      }
      <Box display={search === '' ? 'block' : 'none'}>
        <Route exact path={`${match.path}`} component={Home} />
        <Route path={`${match.path}/styles`} component={Styles} />
        <Route path={`${match.path}/business`} component={Business} />
        <Route path={`${match.path}/favourites`} component={Favourites} />
        <Route path={`${match.path}/template/:id`} component={Template} />
        <Route path={`${match.path}/category/:id`} component={SubCategory} />
      </Box>
      <Box position="fixed" width={1} bottom="0">
        <BottomNavigation
          showLabels
          value={location.pathname.endsWith('/favourites') ? 'favourites' : 'home'}
          className={classes.navigation}
        >
          <BottomNavigationAction
            component={Link}
            to={`${match.path}`}
            label="Home"
            value="home"
            icon={<HomeIcon />}
            classes={{
              wrapper: classes.navigationActionWrapper,
              label: classes.navigationActionLabel
            }}
          />
          <BottomNavigationAction
            component={Link}
            to={`${match.path}/favourites`}
            label="Favourites"
            value="favourites"
            icon={<StarIcon />}
            classes={{
              wrapper: classes.navigationActionWrapper,
              label: classes.navigationActionLabel
            }}
          />
          <BottomNavigationAction
            label="App"
            value="app"
            // icon={<LogoIcon />}
            classes={{
              wrapper: classes.navigationActionWrapper,
              label: classes.navigationActionLabel
            }}
            onClick={handleClose}
          />
        </BottomNavigation>
      </Box>
    </Box>
  )
}

New.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default New
