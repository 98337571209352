import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./App.css";

import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import PrivateRoute from "./PrivateRoute";
import PrivateRouteUser from "./PrivateRouteUser";
import Home from "./components/Home";
import DesignerPanel from "./components/Designer/DesignerPanel";
import UserPanel from "./components/User/UserPanel";

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Lato", "sans-sarif"].join(", "),
    subtitle1: {
      fontSize: "1.125rem",
    },
  },
  palette: {
    primary: { main: "#016BDF" },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
        // borderRadius: '16px'
      },
    },
  },
});

const App = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Switch>
          <PrivateRoute path="/publisher" component={DesignerPanel} />
          <PrivateRouteUser path="/user" component={UserPanel} />
          <Route path="/" component={Home} />
        </Switch>
      </Router>
    </MuiThemeProvider>
  );
};

export default App;

// export default App

// import React from 'react';
// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
