import React, { useState } from "react";
import PropTypes from "prop-types";

import HttpClient from "../../services/HttpClient";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
// import LogoIcon from '../../icons/LogoIcon'

const useStyles = makeStyles(() => ({
  root: {
    minHeight: "60vh",
  },
  // logo: {
  //   fontSize: '4.4rem',
  //   marginTop: '16%',
  //   marginBottom: '3%',
  // },
  actions: {
    marginTop: "2%",
    marginBottom: "20%",
    justifyContent: "flex-end",
  },
  live: {
    color: "#007AFF",
    fontWeight: "bold",
  },
  title: {
    color: "#0E1F4A",
    font: "lato",
    fontSize: "35px",
    lineHeight: 1.2,
    fontWeight: "bold",
    marginBottom: 7,
  },
  contact: {
    color: "#29406B",
  },
}));

const ReConfirmation = ({ email, onAuthErrorChange }) => {
  const classes = useStyles();
  const [emailSent, setEmailSent] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    HttpClient.get(`/account/confirmation/new?email=${email}`)
      .then(() => setEmailSent(true))
      .catch((error) => console.log("Error: ", error));
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={true}
      onClose={() => onAuthErrorChange(null)}
      aria-labelledby="sign-in-dialog-title"
      classes={{ paper: classes.root }}
    >
      <Grid container>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <DialogTitle id="registration-dialog-title">
          <Typography className={classes.title}>
              <span className={classes.live}> Live</span>
              <span className={classes.contact}>Contact</span>
            </Typography>
          </DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent>
              {emailSent ? (
                <React.Fragment>
                  <Typography
                    variant="h6"
                    component="h2"
                    color="textPrimary"
                    gutterBottom
                  >
                    Confirmation e-mail sent to {email}
                  </Typography>
                  <div>
                    Activation email is being sent now. Please activate your
                    account by clicking on the link in the activation email.
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Typography
                    variant="h6"
                    component="h2"
                    color="textPrimary"
                    gutterBottom
                  >
                    Resend confirmation e-mail to {email}
                  </Typography>
                  <div>
                    Your account needs to be activated before log in. Please
                    activate it by clicking on the link in the activation email
                    sent to you after registration. Click "Resend e-mail" if you
                    didn't already get the activation email.
                  </div>
                </React.Fragment>
              )}
            </DialogContent>
            <DialogActions className={classes.actions}>
              <Button
                variant="contained"
                size="small"
                onClick={() => onAuthErrorChange(null)}
              >
                Close
              </Button>
              {!emailSent ? (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  Resend e-mail
                </Button>
              ) : null}
            </DialogActions>
          </form>
        </Grid>
      </Grid>
    </Dialog>
  );
};

ReConfirmation.propTypes = {
  email: PropTypes.string.isRequired,
  onAuthErrorChange: PropTypes.func.isRequired,
};

export default ReConfirmation;
