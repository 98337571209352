import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import { getRandomInt } from '../../../../utils/math'

const useStyles = makeStyles(theme => ({
  root: {
    columns: 2,
    columnGap: theme.spacing(2),
    marginTop: -theme.spacing(2),
  },
  svgBox: {
    '& svg': {
      height: 'auto',
      maxWidth: '100%',
      maxHeight: '100%',
      marginBottom: theme.spacing(2),
      borderRadius: 'inherit',
    },
  },
}))

const Templates = ({ url }) => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [templates, setTemplates] = useState([])
  const [loading, setLoading] = useState(true)
  
  useEffect(() => {
    setLoading(true)
    HttpClient.get(url, { Authorization: `Bearer ${authData.authToken}` })
      .then(res => {
        setTemplates(res)
        setLoading(false)
      })
      .catch(error => {
        console.log('Error getting templates: ', error)
        setTemplates([])
        setLoading(false)
      })
  }, [authData.authToken, url])

  return (
    <Box
      p={2}
      className={classes.root}
    >
      {templates.map(template => {
        let formatType = getRandomInt(3)+1
        const format = template.formats.find(f => f.type === formatType)
        const formatSvg = format ? format.previewImage : ''
        return(
          <Box key={template.id}>
            <Box
              component={Link}
              to={`/user/cards/new/template/${template.id}`}
              className={classes.svgBox}
              dangerouslySetInnerHTML={{
                __html: (formatSvg||'')
              }}
              style={{
                borderRadius: `${format ? format.cornerRadius : 0}px`
              }}
            />
          </Box>
        )
      })}
    </Box>
  )
}

Templates.propTypes = {
  url: PropTypes.string.isRequired,
}

export default Templates
