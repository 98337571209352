import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import PropagateLoader from 'react-spinners/PropagateLoader'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { Box, Grid, Typography, Divider, Button } from '@material-ui/core'
import { isEmpty, get, first, omit, isNull } from 'lodash/fp'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import RadioButton from '../../RadioButton'
import Autocomplete from '../../Autocomplete'
import CancelIcon from '@material-ui/icons/Close'
import HttpClient from '../../../services/HttpClient'
import AuthContext from '../../../contexts/AuthContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faList } from '@fortawesome/free-solid-svg-icons'
import Slider from './Slider/ImageSlider'
import ListModal from './ListModal'
import SortModal from './SortModal'
import SendForRevisionModal from './SendForRevisionModal'
import NoRecordFound from './NoRecordFound'
import TemplatePublisher from './TemplatePublisher'
import DeleteAlertDialog from './DeleteAlertDialog'
const useStyles = makeStyles(theme => ({
  paddingX: {
    padding: '0 15px',
  },
  mainCardWrapper: {
    maxWidth: '1240px',
    padding: '15px',
    position: 'relative',
    margin: '0 auto',
  },
  pageTitle: {
    fontSize: '20px',
    fontWeight: '500',
    margin: '25px 0px -25px 0',
    color: '#000000',
  },
  cardContentWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 30,
  },
  contentLeftSection: {
    maxWidth: '200px',
    maxHeight: '900px',
    overflowY: 'scroll',
    flex: '0 0 200px',
    paddingTop: 5,
    '& svg': {
      width: '165px',
      height: '100%',
    },
  },
  verticalImg: {
    paddingBottom: 16,
  },
  squareImg: {
    paddingBottom: 10,
  },
  horizontalImg: {
    paddingBottom: 16,
  },
  contentRightSection: {
    maxWidth: 'calc(100% - 270px)',
    flex: '0 0 calc(100% - 270px)',
    paddingTop: '15px',
    '& label': {
      fontSize: '20px',
      fontWeight: '500',
      color: '#000000',
    },
  },
  saveDraftsBtn: {
    maxWidth: '150px',
    width: '100%',
    padding: '0 15px',
    height: '40px',
    fontSize: '14px',
    fontWeight: '700',
    color: '#000',
    backgroundColor: '#F0F0F0',
    border: '1px solid #979797',
    borderRadius: '4px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  submittingsBtn: {
    maxWidth: '100px',
    width: '100%',
    padding: '0 15px',
    height: '40px',
    lineHeight: '40px',
    fontSize: '14px',
    fontWeight: '600',
    color: '#fff',
    backgroundColor: '#000',
    marginLeft: '15px',
    border: '1px solid #000',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  error: {
    marginLeft: '5px',
    fontSize: '13px',
    color: 'red',
  },
  inputStyling: {
    width: '100%',
    height: '30px',
    lineHeight: '30px',
    fontSize: '14px',
    fontWeight: '400',
    border: '1px solid #D0D0D0',
    borderRadius: '5px',
    color: '#000',
    padding: '0 15px',
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: '#000',
    },
  },
  titleDescription: {
    fontSize: '14px',
    color: '#000000',
  },
  marginBottomHundred: {
    marginBottom: '100px',
  },
  marginBottom30: {
    marginBottom: '30px',
  },
  marginBottom60: {
    marginBottom: '60px',
  },
  textareaWidth: {
    width: '100%',
    height: '240px',
    overflowY: 'auto',
    padding: '15px',
    fontSize: '16px',
    lineHeight: '1.5',
    resize: 'none',
    fontWeight: '400',
    border: '1px solid #d0d0d0',
    borderRadius: '5px',
    fontFamily: ['Lato', 'sans-sarif'],
    '&::placeholder': {
      color: '#000',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  textMuted: {
    color: '#000000',
    opacity: '31%',
  },
  detailedText: {
    fontSize: '20px',
    fontWeight: '500',
    marginBottom: '60px',
    color: '#000000',
  },
  chip: {
    position: 'relative',
    padding: '5px 10px',
    width: 'auto',
    fontSize: '14px',
    fontweight: '400',
    backgroundColor: '#fff',
    color: '#000',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid #ddd',
  },
  borderRight: {
    position: 'absolute',
    height: '100%',
    background: '#ddd',
    right: '40px',
    top: '0',
  },
  thankyouMsg: {
    '& p': {
      fontSize: '28px',
    },
    '& span': {
      fontSize: '18px',
    },
  },
  inputTags: {
    '& input': {
      width: '100%',
      height: '30px',
      lineHeight: '30px',
      fontSize: '14px',
      fontWeight: '400',
      border: '1px solid #D0D0D0',
      borderRadius: '5px',
      color: '#000',
      padding: '0 15px',
      '&:focus': {
        outline: 'none',
      },
      '&::placeholder': {
        color: '#989c9c',
      },
    },
  },

  [theme.breakpoints.down('xs')]: {
    body: {
      overflowY: 'auto !important',
    },
  },
  typoText: {
    color: '#0D0D0D',
    fontWeight: 700,
  },
  dividerBottom: {
    margin: '10px 0 25px 0',
  },
  btnTextColor: {
    color: '#000000',
    fontWeight: 600,
  },
  editIcons: {
    display: 'flex',
    marginLeft: '20px',
    cursor: 'pointer',
  },

  editIconsMargin: {
    marginRight: '20px',
    marginLeft: '20px',
    cursor: 'pointer',
  },
  textCenter: {
    textAlign: 'center',
  },
  marginLeft30: {
    marginLeft: '30px',
  },
  scoreInput: {
    border: '1px solid #979797',
    borderRadius: '2px',
    height: '35px',
    width: '40px',
    textAlign: 'center',
    '&:disabled': {
      background: 'rgb(0 0 0 / 4%)',
    },
  },
  iconHeight: {
    fontSize: '30px',
    marginLeft: '10px',
    marginTop: '-2px',
  },
  loader: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    zIndex: '9999999',
    transform: 'translate(-50%, -50%)',
  },
  customBtn: {
    maxWidth: '100px',
    padding: '0 15px',
    height: '40px',
    lineHeight: '40px',
    fontSize: '14px',
    fontWeight: '600',
    marginLeft: '15px',
    color: '#fff',
    border: '1px solid #000',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  dangerBg: {
    backgroundColor: '#DE2121',
  },
  purpleBg: {
    backgroundColor: '#78559B',
  },
  blackBg: {
    backgroundColor: '#000',
  },
  blackColor: {
    color: '#000',
  },
}))

const TemplateSubmitForm = () => {
  const classes = useStyles()
  const history = useHistory()
  const urlParams = new URLSearchParams(window.location.search)
  const templateID = urlParams.get('id')
  const ReactTags = require('react-tag-input').WithOutContext

  const [authData] = useContext(AuthContext)
  const [personalExpressions, setPersonalExpressions] = useState([])
  const [selectedPersonalExpression, setSelectedPersonalExpression] = useState(
    []
  )
  const [trendings, setTrendings] = useState([])
  const [selectedTrendings, setSelectedTrendings] = useState([])
  const [styles, setStyles] = useState([])
  const [selectedStyle, setSelectedStyle] = useState([])
  const [currentTemplate, setCurrentTemplate] = useState({})

  const [industries, setIndustries] = useState([])
  const [selectedIndustries, setSelectIndustries] = useState([])

  const [themes, setThemes] = useState([])
  const [selectedThemes, setSelectThemes] = useState([])
  const [occasions, setOccasions] = useState([])
  const [selectedOccasions, setSelectOccasions] = useState([])
  const [selectKeywords, setSelectKeywords] = useState([])
  const [keywords, setKeywords] = useState([])

  const [tags, setTags] = useState(['example tag'])
  const [formIsValid, setFormIsValid] = useState(false)
  const [errors, setErrors] = useState({})
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [validations, setValidations] = useState([{}])
  const [open, setOpen] = useState(false)
  const [openList, setOpenList] = useState(false)
  const [listingData, setListingData] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [categoryId, setCategoryId] = useState(null)
  const [openFeedback, setOpenFeedback] = useState(false)
  const [refetchData, setRefetchData] = useState(false)
  const [requestedTemplates, setRequestedTemplates] = useState([])
  const [loading, setLoading] = useState(false)
  const [publishedTemplate, setPublishedTemplate] = useState({})
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const validationModal = {
    id: 1,
    headerText: 'Category Validation',
    bodyText: [
      {
        id: 1,
        title:
          'You need to choose at least one category from Personal Expression or Business or Theme or Occasion.',
      },
    ],
    footerText: null,
    checkBox: false,
  }

  const notify = msg => toast(msg)

  const [modalContent, setModalContent] = useState(validationModal)

  const handleClickOpen = () => {
    for (var i = 0; i < validations.length; i++) {
      if (
        Object.keys(errors).length === 0 &&
        validations[i].requiredMinOne &&
        validations[i].value &&
        isEmpty(validations[i].value)
      ) {
        setModalContent(validationModal)
        setOpen(true)
      } else if (
        Object.keys(errors).length === 0 &&
        validations[i].requiredMinOne &&
        !isEmpty(validations[i].value)
      ) {
        setOpen(false)
        return true
      }
    }
  }

  const handleClose = () => {
    setOpen(false)
    setModalContent(validationModal)
  }
  const handleCloseList = () => {
    setOpenList(false)
  }
  const [params, setParams] = useState({
    name: get('name', currentTemplate),
    description: '',
    price: 0,
    categories: [],
    tags: [],
    feedback: '',
  })

  const KeyCodes = {
    comma: 188,
    enter: 13,
  }

  const delimiters = [KeyCodes.comma, KeyCodes.enter]

  const selectableArrayHash = [
    {
      id: 0,
      value: setSelectKeywords,
      type: selectKeywords,
      paramsType: 'tags',
      errorKey: 'Keyword',
      table: 'tags',
    },
    {
      id: 1,
      value: setSelectedStyle,
      type: selectedStyle,
      paramsType: 'categories',
      setResult: setStyles,
      table: 'categories',
      typeId: 1,
    },
    {
      id: 2,
      value: setSelectedPersonalExpression,
      type: selectedPersonalExpression,
      paramsType: 'categories',
      wordMaxLimit: 2,
      errorKey: 'Personal Expression',
      setResult: setPersonalExpressions,
      table: 'categories',
      typeId: 2,
    },
    {
      id: 3,
      value: setSelectIndustries,
      type: selectedIndustries,
      paramsType: 'categories',
      setResult: setIndustries,
      table: 'categories',
      typeId: 3,
    },
    {
      id: 4,
      value: setSelectThemes,
      type: selectedThemes,
      paramsType: 'categories',
      wordMaxLimit: 3,
      errorKey: 'Theme',
      setResult: setThemes,
      table: 'categories',
      typeId: 4,
    },
    {
      id: 5,
      value: setSelectOccasions,
      type: selectedOccasions,
      paramsType: 'categories',
      wordMaxLimit: 3,
      errorKey: 'Occasion',
      setResult: setOccasions,
      table: 'categories',
      typeId: 5,
    },
    {
      id: 6,
      value: setSelectedTrendings,
      type: selectedTrendings,
      paramsType: 'categories',
      wordMaxLimit: 3,
      errorKey: 'Trending',
      setResult: setTrendings,
      table: 'categories',
      typeId: 6,
    },
  ]

  const handleCategoryValidation = () => {
    let error = {}
    setErrors(error)
    for (var i = 0; i < validations.length; i++) {
      if (
        validations[i].value === '' ||
        (!validations[i].requiredMinOne &&
          validations[i].value &&
          isEmpty(validations[i].value))
      ) {
        setFormIsValid(false)
        error[validations[i].key] = [validations[i].key] + ' cannot be blank'
        setErrors(error)
      }
    }
  }

  useEffect(() => {
    if (get('name', currentTemplate)) {
      setValidations([
        { key: 'Name', value: params.name },
        { key: 'Description', value: params.description },
        {
          key: 'Personal Expression',
          value: selectedPersonalExpression,
          requiredMinOne: true,
        },
        { key: 'Design Style', value: selectedStyle },
        { key: 'Industry', value: selectedIndustries, requiredMinOne: true },
        { key: 'Theme', value: selectedThemes, requiredMinOne: true },
        { key: 'Occasion', value: selectedOccasions, requiredMinOne: true },
        { key: 'Trending', value: selectedTrendings, requiredMinOne: true },
      ])
    }
  }, [
    currentTemplate,
    params.name,
    params.description,
    selectedStyle,
    selectedPersonalExpression,
    selectedOccasions,
    selectedThemes,
    selectedIndustries,
    selectedTrendings,
    setFormIsValid,
  ])

  useEffect(() => {
    setLoading(true)
    HttpClient.get('/categories/search/3?approved=true', {
      Authorization: `Bearer ${authData.authToken}`,
    })
      .then(res => {
        setLoading(false)
        omitObject(res, selectableArrayHashById(3))
        setIndustries(res)
      })
      .catch(() => {
        setLoading(false)
        setIndustries([])
      })
  }, [authData.authToken, selectedIndustries, openList])

  useEffect(() => {
    setLoading(true)
    HttpClient.get('/categories/search/1?approved=true', {
      Authorization: `Bearer ${authData.authToken}`,
    })
      .then(res => {
        setLoading(false)
        omitObject(res, selectableArrayHashById(1))
        setStyles(res)
      })
      .catch(() => {
        setLoading(false)
        setStyles([])
      })
  }, [authData.authToken, selectedStyle, openList])

  const omitObject = (res, category) => {
    for (var i = 0; i < category.type.length; i++) {
      var index = res.findIndex(function (o) {
        return o.id === parseInt(category.type[i].id, 10)
      })
      if (index !== -1) res.splice(index, 1)
    }
    return res
  }

  const searchCategories = (text, category) => {
    if (/\S/.test(text)) {
      setLoading(true)
      HttpClient.get(
        '/categories/search/' + category.id + '?approved=true&filter=' + text,
        {
          Authorization: `Bearer ${authData.authToken}`,
        }
      )
        .then(res => {
          setLoading(false)
          omitObject(res, category)
          category.setResult([...tagHash(res)])
        })
        .catch(() => {
          setLoading(false)
          category.setResult([])
        })
    }
  }

  const fetchTags = text => {
    setLoading(true)
    if (/\S/.test(text)) {
      HttpClient.get('/tags?title=' + text, {
        Authorization: `Bearer ${authData.authToken}`,
      })
        .then(res => {
          setLoading(false)
          omitObject(res, selectableArrayHashById(0))
          setKeywords([...tagHash(res)])
        })
        .catch(() => {
          setLoading(false)
          setKeywords([])
        })
    }
  }

  const tagHash = res => {
    const allTags = []
    for (var i = 0; i < res.length; i++) {
      allTags.push({ id: '' + res[i].id, text: res[i].title })
    }
    return allTags
  }

  const fetchTemplate = id => {
    setLoading(true)
    HttpClient.get(`/templates/${id}`, {
      Authorization: `Bearer ${authData.authToken}`,
    })
      .then(res => {
        setLoading(false)
        setCurrentTemplate(res)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const handleSelectedStyle = object => {
    setSelectedStyle([...selectedStyle, object])
  }

  const handleSelectedIndustry = object => {
    setSelectIndustries([...selectedIndustries, object])
  }

  const removeObject = (id, data, setData, type) => {
    const arr = data.filter(item => item.id !== id)
    setData(arr)
    let index
    if (type === 'tags') {
      index = params[type].indexOf(parseInt(id, 10))
    } else {
      index = params[type]
        .map(item => item.categoryId)
        .indexOf(parseInt(id, 10))
    }
    ~index && params[type].splice(index, 1)
    handleCategoryValidation()
  }

  const publish = () => {
    let requiredParams = omit(['feedback'])(params)
    HttpClient.post(
      `/templates/${currentTemplate.id}/publish`,
      requiredParams,
      {
        Authorization: `Bearer ${authData.authToken}`,
      }
    )
      .then(() => {
        setPublishedTemplate({})
        setLoading(false)
        setRefetchData(true)
        scrollToTop()
        notify('Template has been published.')
      })
      .catch(error => console.log('Error publishing template: ', error))
  }

  const createTag = (tagParams, hash) => {
    setLoading(true)
    HttpClient.post(`/${hash.table}`, tagParams, {
      Authorization: `Bearer ${authData.authToken}`,
    })
      .then(res => {
        setLoading(false)
        if (!openList) {
          hash.value([...hash.type, { id: '' + res.id, text: res.title }])
          if (hash.typeId) {
            params[hash.paramsType].push({
              categoryId: parseInt(res.id, 10),
              qualityScore: 0,
            })
          } else {
            params[hash.paramsType].push(parseInt(res.id, 10))
          }
        } else {
          setListingData(sortByName([...listingData, res]))
        }
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const onInputChange = evt => {
    setParams({
      ...params,
      [evt.target.name]: evt.target.value,
    })
    handleCategoryValidation()
  }

  const onSubmit = e => {
    e.preventDefault()
    handleCategoryValidation()
    setIsSubmitted(true)
    if (formIsValid && handleClickOpen()) {
      params.price = parseFloat(params.price, 10)
      setLoading(true)
      publish()
    }
  }

  const onKeyPress = e => {
    if (e.which === 13) {
      e.preventDefault()
    }
  }

  const addTag = (type, hash) => {
    let tag = document.getElementById(type)
    if (tag && tag.value) {
      tag = { id: '' + tag.value, text: tag.value }
      handleAddition(tag, hash)
      document.getElementById(type).value = ''
    }
  }

  useEffect(() => {
    if (get('name', currentTemplate)) {
      params.name = get('name', currentTemplate)
      params.description = get('description', currentTemplate) || ''
      params.price = get('price', currentTemplate) || params.price
      if (!isEmpty(currentTemplate.categories)) {
        params.categories = []
        for (var i = 0; i < currentTemplate.categories.length; i++) {
          if (currentTemplate.categories[i]) {
            params.categories.push({
              categoryId: currentTemplate.categories[i].categoryId,
              qualityScore: currentTemplate.categories[i].qualityScore,
            })
          }
        }
        for (var i = 0; i < selectableArrayHash.length; i++) {
          var v = selectableArrayHash[i].value
          v([])
          var array = []
          for (var j = 0; j < currentTemplate.categories.length; j++) {
            if (
              currentTemplate.categories[j].category.type ===
              selectableArrayHash[i].id
            ) {
              array.push(currentTemplate.categories[j].category)
              v(array)
            }
          }
        }
      }
      setSelectKeywords([])
      if (!isEmpty(currentTemplate.tags)) {
        var array = []
        params.tags = []
        for (var i = 0; i < currentTemplate.tags.length; i++) {
          if (currentTemplate.tags[i].tag) {
            params.tags.push(currentTemplate.tags[i].tagId)
            array.push(currentTemplate.tags[i].tag)
            setSelectKeywords(array)
          }
        }
      }
    }
  }, [currentTemplate])

  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      setFormIsValid(true)
    } else {
      setFormIsValid(false)
    }
  }, [errors, handleCategoryValidation])

  useEffect(() => {
    handleCategoryValidation()
  }, [isSubmitted, currentTemplate, validations])

  const handleAddition = (tag, hash) => {
    let error = {}
    let id = null
    if (errors[hash.errorKey]) {
      delete errors[hash.errorKey]
    }
    if (!parseInt(tag.id, 10)) {
      if (tag.text.split(' ').length > hash.wordMaxLimit) {
        error[hash.errorKey] =
          'You can add maximum ' + hash.wordMaxLimit + ' words'
        setErrors(error)
        return false
      }
      if (hash.typeId) {
        createTag({ title: tag.text, type: hash.typeId, approve: true }, hash)
      } else {
        createTag({ title: tag.text, type: hash.typeId }, hash)
      }
    } else {
      hash.value([...hash.type, { id: '' + tag.id, text: tag.text }])
      if (hash.typeId) {
        params[hash.paramsType].push({
          categoryId: parseInt(tag.id, 10),
          qualityScore: 0,
        })
      } else {
        params[hash.paramsType].push(parseInt(tag.id, 10))
      }
    }
  }

  const selectableArrayHashById = id => {
    return selectableArrayHash.find(x => x.id === id)
  }

  useEffect(() => {
    HttpClient.get('/templates?status=5', {
      Authorization: `Bearer ${authData.authToken}`,
    })
      .then(res => {
        if (!isEmpty(Object.entries(publishedTemplate))) {
          setRequestedTemplates(sortByStatus([...res, publishedTemplate]))
        } else {
          setRequestedTemplates(sortByStatus(res))
        }
        fetchTemplate(get('id', publishedTemplate) || first(res).id)
      })
      .catch(error => {})
  }, [authData.authToken, refetchData, publishedTemplate])

  useEffect(() => {
    if (templateID) {
      HttpClient.get('/templates/' + templateID, {
        Authorization: `Bearer ${authData.authToken}`,
      })
        .then(res => {
          setPublishedTemplate(res)
        })
        .catch(error => {})
    }
  }, [templateID])

  const getCreatedDate = createdAt => {
    return moment(createdAt).format('DD MMMM YYYY')
  }

  const updateModal = (categoryId, headerText, footerText, checkBox) => {
    setLoading(true)
    HttpClient.get('/categories/search/' + categoryId + '?approved=true', {
      Authorization: `Bearer ${authData.authToken}`,
    })
      .then(res => {
        setLoading(false)
        setModalContent({
          id: 1,
          headerText: headerText,
          bodyText: sortBySequence(res),
          footerText: footerText,
          checkBox: checkBox,
          categoryId: categoryId,
        })
        setOpen(true)
      })
      .catch(error => {
        setLoading(false)
        setListingData([])
      })
  }

  const updateModalList = categoryId => {
    setLoading(true)
    HttpClient.get('/categories/search/' + categoryId + '?approved=true', {
      Authorization: `Bearer ${authData.authToken}`,
    })
      .then(res => {
        setLoading(false)
        setListingData(sortByName(res))
        setCategoryId(categoryId)
        setOpenList(true)
      })
      .catch(error => {
        setLoading(false)
        setListingData([])
      })
  }

  const scrollToTop = () => {
    document.getElementById('mainPageWrapper').scroll(0, 0)
  }

  const openFeedbackModal = e => {
    e.preventDefault()
    setOpenFeedback(true)
  }

  const rejectTemplate = () => {
    setLoading(true)
    HttpClient.post(
      `/templates/${currentTemplate.id}/reject`,
      { feedback: params.feedback },
      {
        Authorization: `Bearer ${authData.authToken}`,
      }
    )
      .then(res => {
        setLoading(false)
        setOpenFeedback(false)
        params.feedback = ''
        setRefetchData(true)
        scrollToTop()
        notify('Template has been sent for revision.')
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const onPause = e => {
    e.preventDefault()
    let requiredParams = omit(['feedback'])(params)
    HttpClient.put(`/templates/${currentTemplate.id}/pause`, requiredParams, {
      Authorization: `Bearer ${authData.authToken}`,
    })
      .then(res => {
        setLoading(false)
        setPublishedTemplate({})
        setRefetchData(true)
        scrollToTop()
        notify('Template has been paused.')
      })
      .catch(error => console.log('Error publishing template: ', error))
  }

  const onDelete = e => {
    HttpClient.delete(
      `/templates/${currentTemplate.id}`,

      {
        Authorization: `Bearer ${authData.authToken}`,
      }
    )
      .then(() => {
        setLoading(false)

        setPublishedTemplate({})
        setRefetchData(true)
        scrollToTop()
        notify('Template has been deleted.')
      })
      .catch(error => console.log('Error publishing template: ', error))
  }

  const sortByName = data => {
    data.sort((a, b) =>
      a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1
    )
    return data
  }

  const sortBySequence = data => {
    data.sort((a, b) => (b.sequence < a.sequence ? 1 : -1))
    return data
  }

  const sortByStatus = data => {
    data.sort((a, b) => (b.status < a.status ? 1 : -1))
    return data
  }

  const updateCategory = (categoryId, paramsCategory) => {
    setLoading(true)
    HttpClient.put(`/categories/${categoryId}`, paramsCategory, {
      Authorization: `Bearer ${authData.authToken}`,
    })
      .then(res => {
        setLoading(false)
        if (!paramsCategory.sequence) {
          updateModalList(paramsCategory.type)
        }
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const onChangeScore = (e, styleCategories) => {
    if (e.target.value > 100 || e.target.value < 0) {
      e.target.value = e.target.value.substr(0, 2)
    }

    var array = []
    for (var i = 0; i < params.categories.length; i++) {
      for (var j = 0; j < styleCategories.length; j++) {
        if (
          params.categories[i].categoryId ===
          parseInt(styleCategories[j].id, 10)
        ) {
          var removeIndex = params.categories
            .map(item => item.categoryId)
            .indexOf(parseInt(styleCategories[j].id, 10))
          ~removeIndex && params.categories.splice(removeIndex, 1)
          array.push({
            categoryId: parseInt(styleCategories[j].id, 10),
            qualityScore: parseFloat(e.target.value, 10),
          })
        }
      }
    }
    setParams({
      ...params,
      ['categories']: [...params.categories, ...array],
    })
  }

  const getCategoryQscore = typeId => {
    for (const cat of params.categories) {
      if (cat.categoryId === typeId) {
        return cat.qualityScore
      }
    }
  }

  return (
    <>
      <ToastContainer />
      <div className={classes.loader}>
        <PropagateLoader color={'#bdbdbd'} loading={loading} size={25} />
      </div>
      <Box overflow="auto" width="100%" id="mainPageWrapper">
        {!isEmpty(requestedTemplates) &&
          currentTemplate &&
          currentTemplate.horizontal && (
            <form onSubmit={onSubmit} onKeyPress={onKeyPress}>
              <Box className={classes.mainCardWrapper}>
                <Box className={classes.cardContentWrapper}>
                  <Box className={classes.contentLeftSection}>
                    {!isEmpty(requestedTemplates) &&
                      requestedTemplates.map((template, index) => (
                        <div>
                          <Box
                            onClick={() => fetchTemplate(template.id)}
                            className={classes.squareImg}
                            dangerouslySetInnerHTML={{
                              __html:
                                template.vertical &&
                                template.vertical.previewImage,
                            }}
                          ></Box>
                        </div>
                      ))}
                  </Box>

                  <Box className={classes.contentRightSection}>
                    <Box display="flex" mb={6}>
                      <Grid align="left" md={7}>
                        <Box>
                          <Slider currentTemplate={currentTemplate} />
                        </Box>
                      </Grid>
                      <Grid md={5}>
                        <Box ml={4} align="right">
                          {publishedTemplate && currentTemplate.status === 2 ? (
                            <>
                              <button
                                type="button"
                                onClick={() =>
                                  history.push(`/publisher/manage-templates?timestamp=${new Date().toISOString()}`)
                                }
                                className={`${classes.customBtn} ${classes.blackColor}`}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                onClick={onPause}
                                className={`${classes.customBtn} ${classes.purpleBg}`}
                              >
                                Pause
                              </button>
                              <button
                                className={`${classes.customBtn} ${classes.blackBg}`}
                              >
                                Save
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type="button"
                                onClick={openFeedbackModal}
                                className={classes.saveDraftsBtn}
                              >
                                Send for Revision
                              </button>
                              <button className={classes.submittingsBtn}>
                                Publish
                              </button>
                            </>
                          )}
                          <button
                            type="button"
                            onClick={() => setOpenDeleteModal(true)}
                            className={`${classes.customBtn} ${classes.dangerBg}`}
                          >
                            Delete
                          </button>
                        </Box>
                      </Grid>
                    </Box>
                    <Grid container className={classes.marginBottom30}>
                      <Grid item xs={12} md={3}>
                        <label>Designer</label>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <Typography>
                          {first(currentTemplate.account.profiles).firstName}{' '}
                          {first(currentTemplate.account.profiles).lastName}
                          <span className={classes.marginLeft30}>
                            {currentTemplate.account.email}
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.marginBottom30}>
                      <Grid item xs={12} md={3}>
                        <label>Template Number</label>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <Typography>{currentTemplate.id}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.marginBottom30}>
                      <Grid item xs={12} md={3}>
                        <label>Template Name</label>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <input
                          autoFocus
                          type={'text'}
                          className={classes.inputStyling}
                          defaultValue={params.name}
                          onChange={onInputChange}
                          name="name"
                        />
                        {isSubmitted && (
                          <span className={classes.error}>
                            {errors['Name']}
                          </span>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container className={classes.marginBottom30}>
                      <Grid item xs={12} md={3}>
                        <label>Number of sides</label>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <Typography>
                          {first(currentTemplate.formats).sides?.length}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.marginBottomHundred}>
                      <Grid item xs={12} md={3}>
                        <label>Date Created</label>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <Typography>
                          {getCreatedDate(currentTemplate.createdAt)}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container className={classes.marginBottom30}>
                      <Grid item xs={12} md={3}>
                        <label>
                          Short Description<span>*</span>
                        </label>
                        <Box className={classes.titleDescription} mt={2}>
                          A short desription that describes your card.
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <textarea
                          className={classes.textareaWidth}
                          onChange={onInputChange}
                          name="description"
                          defaultValue={params.description}
                          handleCategoryValidation={handleCategoryValidation}
                        ></textarea>
                        {isSubmitted && (
                          <span className={classes.error}>
                            {errors['Description']}
                          </span>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} md={9}>
                        <Typography
                          variant="h5"
                          component="h2"
                          className={classes.typoText}
                        >
                          Display Categories
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={1} className={classes.textCenter}>
                        <Typography
                          variant="h9"
                          component="h7"
                          className={classes.typoText}
                        >
                          List
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={1} className={classes.textCenter}>
                        <Typography
                          variant="h9"
                          component="h7"
                          className={classes.typoText}
                        >
                          Sort
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={1} className={classes.textCenter}>
                        <Typography
                          variant="h9"
                          component="h7"
                          className={classes.typoText}
                        >
                          QScore
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider dark className={classes.dividerBottom} />
                    <Grid container className={classes.marginBottom60}>
                      <Grid item xs={12} md={3}>
                        <label>
                          Design Style(s)<span>*</span>
                        </label>
                        <Box className={classes.titleDescription} mt={2}>
                          Choose the style descriptions you think apply to this
                          design.
                        </Box>
                        <Box className={classes.textMuted}>
                          Choose as many as you think apply.
                        </Box>
                      </Grid>
                      <Grid item xs={10} md={6}>
                        <Autocomplete
                          hideIcon={true}
                          options={sortByName(styles)}
                          placeholder="Select styles that apply to this design?"
                          onAddCategory={handleSelectedStyle}
                          params={params}
                          isCategory={true}
                          handleCategoryValidation={handleCategoryValidation}
                          selectedValues={selectedStyle}
                        />
                        {isSubmitted && (
                          <span className={classes.error}>
                            {errors['Design Style']}
                          </span>
                        )}

                        <Box my={2} display="flex" flexWrap="wrap">
                          {selectedStyle.map((style, index) => (
                            <Box
                              key={index}
                              component="span"
                              display="inline-block"
                              bgcolor="#e9e3ff"
                              py={0.5}
                              px={1}
                              m={0.5}
                              borderRadius={4}
                              className={classes.chip}
                            >
                              {style.title}
                              <span className={classes.borderRight}></span>
                              <CancelIcon
                                onClick={() =>
                                  removeObject(
                                    style.id,
                                    selectedStyle,
                                    setSelectedStyle,
                                    'categories'
                                  )
                                }
                                fontSize="small"
                                className={classes.icon}
                              />
                            </Box>
                          ))}
                        </Box>
                      </Grid>
                      <Grid md={1}>
                        <Box className={classes.editIcons}>
                          <Box component="">
                            <FontAwesomeIcon
                              height={100}
                              className={classes.iconHeight}
                              icon={faEdit}
                              onClick={() => updateModalList(1)}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid md={1}>
                        <Box component="" className={classes.editIconsMargin}>
                          <FontAwesomeIcon
                            height={100}
                            className={classes.iconHeight}
                            icon={faList}
                            onClick={() =>
                              updateModal(
                                1,
                                'Style Values Display Order',
                                'Tap & Hold to select and move ',
                                true
                              )
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid md={1}>
                        <Box className={classes.editIcons}>
                          <input
                            type="number"
                            min="0"
                            max="100"
                            autocomplete="off"
                            defaultValue={getCategoryQscore(
                              get('id', first(selectedStyle))
                            )}
                            className={classes.scoreInput}
                            name="qualityScore"
                            onChange={e => onChangeScore(e, selectedStyle)}
                            disabled={isEmpty(selectedStyle)}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.marginBottomHundred}>
                      <Grid item xs={12} md={3}>
                        <label>Personal Expression</label>
                        <Box className={classes.titleDescription} mt={2}>
                          Give a description to the personal expression you’ve
                          developed.
                        </Box>
                      </Grid>
                      <Grid item xs={10} md={5}>
                        <div className={classes.inputTags}>
                          <ReactTags
                            suggestions={personalExpressions}
                            handleAddition={event =>
                              handleAddition(event, selectableArrayHashById(2))
                            }
                            delimiters={delimiters}
                            autofocus={false}
                            handleInputChange={event =>
                              searchCategories(
                                event,
                                selectableArrayHashById(2)
                              )
                            }
                            placeholder="Enter a 1 or 2 word expression that describes your design?"
                            id={'personalExpressionTag'}
                          />
                        </div>
                        <span className={classes.error}>
                          {errors['Personal Expression']}
                        </span>
                        <Box my={2} display="flex">
                          {selectedPersonalExpression.map(
                            (personalExpression, index) => (
                              <Box
                                key={index}
                                component="span"
                                display="inline-block"
                                bgcolor="#e9e3ff"
                                py={0.5}
                                px={1}
                                m={0.5}
                                borderRadius={4}
                                className={classes.chip}
                              >
                                {personalExpression.text ||
                                  personalExpression.title}
                                <span className={classes.borderRight}></span>
                                <CancelIcon
                                  onClick={() =>
                                    removeObject(
                                      personalExpression.id,
                                      selectedPersonalExpression,
                                      setSelectedPersonalExpression,
                                      'categories'
                                    )
                                  }
                                  fontSize="small"
                                  className={classes.icon}
                                />
                              </Box>
                            )
                          )}
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        md={1}
                        onClick={() =>
                          addTag(
                            'personalExpressionTag',
                            selectableArrayHashById(2)
                          )
                        }
                      >
                        <Button
                          className={classes.btnTextColor}
                          size="small"
                          variant="outlined"
                        >
                          Add
                        </Button>
                      </Grid>
                      <Grid md={1}>
                        <Box className={classes.editIcons}>
                          <FontAwesomeIcon
                            className={classes.iconHeight}
                            icon={faEdit}
                            onClick={() => updateModalList(2)}
                          />
                        </Box>
                      </Grid>
                      <Grid md={1}>
                        <Box component="" className={classes.editIconsMargin}>
                          <FontAwesomeIcon
                            height={100}
                            className={classes.iconHeight}
                            icon={faList}
                            onClick={() =>
                              updateModal(
                                2,
                                'Style Values Display Order',
                                'Tap & Hold to select and move ',
                                true
                              )
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid md={1}>
                        <Box className={classes.editIcons}>
                          <input
                            type="number"
                            min="0"
                            max="100"
                            defaultValue={getCategoryQscore(
                              get('id', first(selectedPersonalExpression))
                            )}
                            autocomplete="off"
                            className={classes.scoreInput}
                            name="qualityScore"
                            onChange={e =>
                              onChangeScore(e, selectedPersonalExpression)
                            }
                            disabled={isEmpty(selectedPersonalExpression)}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.marginBottom60}>
                      <Grid item xs={12} md={3}>
                        <label>Business</label>
                        <Box className={classes.titleDescription} mt={2}>
                          Define which industry or industries you think this
                          design might be best for.
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Autocomplete
                          hideIco={true}
                          options={sortByName(industries)}
                          placeholder="Choose business types or industries where your design could apply?"
                          onAddCategory={handleSelectedIndustry}
                          params={params}
                          isCategory={true}
                          handleCategoryValidation={handleCategoryValidation}
                          selectedValues={selectedIndustries}
                        />
                        {isSubmitted && (
                          <span className={classes.error}>
                            {errors['Industry']}
                          </span>
                        )}
                        <Box my={2} display="flex" flexWrap="wrap">
                          {selectedIndustries.map((industry, index) => (
                            <Box
                              key={index}
                              component="span"
                              display="inline-block"
                              bgcolor="#e9e3ff"
                              py={0.5}
                              px={1}
                              m={0.5}
                              borderRadius={4}
                              className={classes.chip}
                            >
                              {industry.title}
                              <span className={classes.borderRight}></span>
                              <CancelIcon
                                onClick={() =>
                                  removeObject(
                                    industry.id,
                                    selectedIndustries,
                                    setSelectIndustries,
                                    'categories'
                                  )
                                }
                                fontSize="small"
                                className={classes.icon}
                              />
                            </Box>
                          ))}
                        </Box>
                      </Grid>
                      <Grid md={1}>
                        <Box className={classes.editIcons}>
                          <FontAwesomeIcon
                            className={classes.iconHeight}
                            icon={faEdit}
                            onClick={() => updateModalList(3)}
                          />
                        </Box>
                      </Grid>
                      <Grid md={1}>
                        <Box component="" className={classes.editIconsMargin}>
                          <FontAwesomeIcon
                            className={classes.iconHeight}
                            icon={faList}
                            onClick={() =>
                              updateModal(
                                3,
                                'Style Values Display Order',
                                'Tap & Hold to select and move ',
                                true
                              )
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid md={1}>
                        <Box className={classes.editIcons}>
                          <input
                            type="number"
                            min="0"
                            max="100"
                            autocomplete="off"
                            defaultValue={getCategoryQscore(
                              get('id', first(selectedIndustries))
                            )}
                            className={classes.scoreInput}
                            name="qualityScore"
                            onChange={e =>
                              onChangeScore(e, selectedIndustries)
                            }
                            disabled={isEmpty(selectedIndustries)}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.marginBottom60}>
                      <Grid item xs={12} md={3}>
                        <label>Theme</label>
                        <Box className={classes.titleDescription} mt={2}>
                          Define the theme/concept.
                        </Box>
                      </Grid>
                      <Grid item xs={10} md={5}>
                        <div className={classes.inputTags}>
                          <ReactTags
                            suggestions={themes}
                            handleAddition={event =>
                              handleAddition(event, selectableArrayHashById(4))
                            }
                            delimiters={delimiters}
                            autofocus={false}
                            handleInputChange={event =>
                              searchCategories(
                                event,
                                selectableArrayHashById(4)
                              )
                            }
                            placeholder="Enter a 1 to 3 words that describes theme of your design?"
                            id={'themeTag'}
                          />
                        </div>
                        <span className={classes.error}>{errors['Theme']}</span>
                        <Box my={2} display="flex" flexWrap="wrap">
                          {selectedThemes.map((theme, index) => (
                            <Box
                              key={index}
                              component="span"
                              display="inline-block"
                              bgcolor="#e9e3ff"
                              py={0.5}
                              px={1}
                              m={0.5}
                              borderRadius={4}
                              className={classes.chip}
                            >
                              {theme.text || theme.title}
                              <span className={classes.borderRight}></span>
                              <CancelIcon
                                onClick={() =>
                                  removeObject(
                                    theme.id,
                                    selectedThemes,
                                    setSelectThemes,
                                    'categories'
                                  )
                                }
                                fontSize="small"
                                className={classes.icon}
                              />
                            </Box>
                          ))}
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        md={1}
                        onClick={() =>
                          addTag('themeTag', selectableArrayHashById(4))
                        }
                      >
                        <Button
                          className={classes.btnTextColor}
                          size="small"
                          variant="outlined"
                        >
                          Add
                        </Button>
                      </Grid>
                      <Grid md={1}>
                        <Box className={classes.editIcons}>
                          <FontAwesomeIcon
                            className={classes.iconHeight}
                            icon={faEdit}
                            onClick={() => updateModalList(4)}
                          />
                        </Box>
                      </Grid>
                      <Grid md={1}>
                        <Box component="" className={classes.editIconsMargin}>
                          <FontAwesomeIcon
                            height={100}
                            className={classes.iconHeight}
                            icon={faList}
                            onClick={() =>
                              updateModal(
                                4,
                                'Style Values Display Order',
                                'Tap & Hold to select and move ',
                                true
                              )
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid md={1}>
                        <Box className={classes.editIcons}>
                          <input
                            type="number"
                            min="0"
                            max="100"
                            autocomplete="off"
                            defaultValue={getCategoryQscore(
                              get('id', first(selectedThemes))
                            )}
                            className={classes.scoreInput}
                            name="qualityScore"
                            onChange={e => onChangeScore(e, selectedThemes)}
                            disabled={isEmpty(selectedThemes)}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.marginBottom60}>
                      <Grid item xs={12} md={3}>
                        <label>Trending</label>
                        <Box className={classes.titleDescription} mt={2}>
                          Define the trending/concept. .
                        </Box>
                      </Grid>
                      <Grid item xs={10} md={5}>
                        <div className={classes.inputTags}>
                          <ReactTags
                            suggestions={trendings}
                            handleAddition={event =>
                              handleAddition(event, selectableArrayHashById(6))
                            }
                            delimiters={delimiters}
                            autofocus={false}
                            handleInputChange={event =>
                              searchCategories(
                                event,
                                selectableArrayHashById(6)
                              )
                            }
                            placeholder="Enter a 1 to 3 words that describes the trending."
                            id={'trendingTag'}
                          />
                        </div>
                        <span className={classes.error}>
                          {errors['Trending']}
                        </span>
                        <Box my={2} display="flex">
                          {selectedTrendings.map((trending, index) => (
                            <Box
                              key={index}
                              component="span"
                              display="inline-block"
                              bgcolor="#e9e3ff"
                              py={0.5}
                              px={1}
                              m={0.5}
                              borderRadius={4}
                              className={classes.chip}
                            >
                              {trending.title || trending.text}
                              <span className={classes.borderRight}></span>
                              <CancelIcon
                                onClick={() =>
                                  removeObject(
                                    trending.id,
                                    selectedTrendings,
                                    setSelectedTrendings,
                                    'categories'
                                  )
                                }
                                fontSize="small"
                                className={classes.icon}
                              />
                            </Box>
                          ))}
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        md={1}
                        onClick={() =>
                          addTag('trendingTag', selectableArrayHashById(6))
                        }
                      >
                        <Button
                          className={classes.btnTextColor}
                          size="small"
                          variant="outlined"
                        >
                          Add
                        </Button>
                      </Grid>
                      <Grid md={1}>
                        <Box className={classes.editIcons}>
                          <FontAwesomeIcon
                            className={classes.iconHeight}
                            icon={faEdit}
                            onClick={() => updateModalList(6)}
                          />
                        </Box>
                      </Grid>
                      <Grid md={1}>
                        <Box component="" className={classes.editIconsMargin}>
                          <FontAwesomeIcon
                            height={100}
                            className={classes.iconHeight}
                            icon={faList}
                            onClick={() =>
                              updateModal(
                                6,
                                'Style Values Display Order',
                                'Tap & Hold to select and move ',
                                true
                              )
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid md={1}>
                        <Box className={classes.editIcons}>
                          <input
                            type="number"
                            min="0"
                            max="100"
                            autocomplete="off"
                            defaultValue={getCategoryQscore(
                              get('id', first(selectedTrendings))
                            )}
                            className={classes.scoreInput}
                            name="qualityScore"
                            onChange={e =>
                              onChangeScore(e, selectedTrendings)
                            }
                            disabled={isEmpty(selectedTrendings)}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.marginBottom60}>
                      <Grid item xs={12} md={3}>
                        <label>Occasion</label>
                        <Box className={classes.titleDescription} mt={2}>
                          Add to cards designed for the occasion, for example
                          graduation party decor.
                        </Box>
                      </Grid>
                      <Grid item xs={10} md={5}>
                        <div className={classes.inputTags}>
                          <ReactTags
                            suggestions={occasions}
                            handleAddition={event =>
                              handleAddition(event, selectableArrayHashById(5))
                            }
                            delimiters={delimiters}
                            autofocus={false}
                            handleInputChange={event =>
                              searchCategories(
                                event,
                                selectableArrayHashById(5)
                              )
                            }
                            placeholder="Enter a 1 to 3 words that describes the occasion."
                            id={'occasionTag'}
                          />
                        </div>
                        <span className={classes.error}>
                          {errors['Occasion']}
                        </span>
                        <Box my={2} display="flex" flexWrap="wrap">
                          {selectedOccasions.map((occasion, index) => (
                            <Box
                              key={index}
                              component="span"
                              display="inline-block"
                              bgcolor="#e9e3ff"
                              py={0.5}
                              px={1}
                              m={0.5}
                              borderRadius={4}
                              className={classes.chip}
                            >
                              {occasion.title || occasion.text}
                              <span className={classes.borderRight}></span>
                              <CancelIcon
                                onClick={() =>
                                  removeObject(
                                    occasion.id,
                                    selectedOccasions,
                                    setSelectOccasions,
                                    'categories'
                                  )
                                }
                                fontSize="small"
                                className={classes.icon}
                              />
                            </Box>
                          ))}
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        md={1}
                        onClick={() =>
                          addTag('occasionTag', selectableArrayHashById(5))
                        }
                      >
                        <Button
                          className={classes.btnTextColor}
                          size="small"
                          variant="outlined"
                        >
                          Add
                        </Button>
                      </Grid>
                      <Grid md={1}>
                        <Box className={classes.editIcons}>
                          <FontAwesomeIcon
                            className={classes.iconHeight}
                            icon={faEdit}
                            onClick={() => updateModalList(5)}
                          />
                        </Box>
                      </Grid>
                      <Grid md={1}>
                        <Box component="" className={classes.editIconsMargin}>
                          <FontAwesomeIcon
                            height={100}
                            className={classes.iconHeight}
                            icon={faList}
                            onClick={() =>
                              updateModal(
                                5,
                                'Style Values Display Order',
                                'Tap & Hold to select and move ',
                                true
                              )
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid md={1}>
                        <Box className={classes.editIcons}>
                          <input
                            type="number"
                            min="0"
                            max="100"
                            autocomplete="off"
                            defaultValue={getCategoryQscore(
                              get('id', first(selectedOccasions))
                            )}
                            className={classes.scoreInput}
                            name="qualityScore"
                            onChange={e =>
                              onChangeScore(e, selectedOccasions)
                            }
                            disabled={isEmpty(selectedOccasions)}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.marginBottom60}>
                      <Grid item xs={12} md={3}>
                        <label>Key Words</label>
                        <Box className={classes.titleDescription} mt={2}>
                          Define keywords that apply. Maximum 10 key words.
                        </Box>
                      </Grid>
                      <Grid item xs={10} md={5}>
                        <div className={classes.inputTags}>
                          <ReactTags
                            suggestions={keywords}
                            handleAddition={event =>
                              handleAddition(event, selectableArrayHashById(0))
                            }
                            delimiters={delimiters}
                            handleInputChange={fetchTags}
                            autofocus={false}
                            placeholder="Shape, color, type, function, etc. "
                            readOnly={selectKeywords.length === 10}
                            id={'keywordTag'}
                          />
                        </div>
                        <span className={classes.error}>
                          {errors['Keyword']}
                        </span>
                        <Box my={2} display="flex" flexWrap="wrap">
                          {!isEmpty(selectKeywords) &&
                            selectKeywords.map((keyword, index) => (
                              <Box
                                key={index}
                                component="span"
                                display="inline-block"
                                bgcolor="#e9e3ff"
                                py={0.5}
                                px={1}
                                m={0.5}
                                borderRadius={4}
                                className={classes.chip}
                              >
                                {keyword.title || keyword.text}
                                <span className={classes.borderRight}></span>
                                <CancelIcon
                                  onClick={() =>
                                    removeObject(
                                      keyword.id,
                                      selectKeywords,
                                      setSelectKeywords,
                                      'tags'
                                    )
                                  }
                                  fontSize="small"
                                  className={classes.icon}
                                />
                              </Box>
                            ))}
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        md={1}
                        onClick={() =>
                          addTag('keywordTag', selectableArrayHashById(0))
                        }
                      >
                        <Button
                          className={classes.btnTextColor}
                          size="small"
                          variant="outlined"
                        >
                          Add
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.marginBottom60}>
                      <Grid item xs={12} md={3}>
                        <label>
                          Sale Price<span>*</span>
                        </label>
                        <Box className={classes.titleDescription} mt={2}>
                          Choose the price you like for this design.
                        </Box>
                        <Box className={classes.titleDescription} mt={2}>
                          You will received 60% of the sale price.
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <RadioButton
                          onInputChange={onInputChange}
                          defaultValue={params.price}
                        />
                      </Grid>
                    </Grid>
                    <Box ml={4} align="right">
                      {publishedTemplate && currentTemplate.status === 2 ? (
                        <>
                          <button
                            type="button"
                            onClick={() =>
                              history.push(`/publisher/manage-templates?timestamp=${new Date().toISOString()}`)
                            }
                            className={`${classes.customBtn} ${classes.blackColor}`}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            onClick={() => onPause()}
                            className={`${classes.customBtn} ${classes.purpleBg}`}
                          >
                            Pause
                          </button>
                          <button
                            className={`${classes.customBtn} ${classes.blackBg}`}
                          >
                            Save
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            onClick={openFeedbackModal}
                            className={classes.saveDraftsBtn}
                          >
                            Send for Revision
                          </button>
                          <button className={classes.submittingsBtn}>
                            Publish
                          </button>
                        </>
                      )}
                      <button
                        type="button"
                        onClick={() => setOpenDeleteModal(true)}
                        className={`${classes.customBtn} ${classes.dangerBg}`}
                      >
                        Delete
                      </button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </form>
          )}
        <Box mt={6}>{isEmpty(requestedTemplates) && <NoRecordFound />}</Box>
      </Box>
      <ListModal
        open={openList}
        onClose={handleCloseList}
        onClick={handleCloseList}
        data={listingData}
        handleAddition={handleAddition}
        searchCategories={searchCategories}
        selectableArrayHashById={selectableArrayHashById}
        categoryList={categoryList}
        categoryId={categoryId}
        addTag={addTag}
        removeCategory={updateCategory}
      />
      <SortModal
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        modalContent={modalContent}
        sortCategory={updateCategory}
      />
      <SendForRevisionModal
        open={openFeedback}
        setOpen={setOpenFeedback}
        feedbackValue={params.feedback}
        onReject={rejectTemplate}
        onInputChange={onInputChange}
      />
      <DeleteAlertDialog
        open={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        onDelete={onDelete}
      />
    </>
  )
}

TemplateSubmitForm.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

export default TemplateSubmitForm
