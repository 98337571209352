import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import New from './Card/New/index'
import Editor from './Card/Editor'

const UserPanel = ({ match }) => {
  return (
    <React.Fragment>
      <Route path={`${match.path}/cards/new`} component={New} />
      <Route path={`${match.path}/cards/:id/design`} component={Editor} />
    </React.Fragment>
  )
}

UserPanel.propTypes = {
  match: PropTypes.object.isRequired,
}

export default UserPanel
